import Webcam from "react-webcam";
import { Tooltip } from "@mui/material";
import { VideoRecord } from "../../utils/interfaces/components.interface";
import { TiMediaRecord } from "react-icons/ti";
import { useTranslation } from "react-i18next";

export const Video = ({
  counter,
  setError,
  webcamRef,
  initialCounter,
  videoConstraints,
  handleStartCaptureClick,
}: VideoRecord) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="actions_to_record">
        {/* Record */}
        <Tooltip arrow placement="top" title="Inicia la grabación">
          <div className="content_action_v">
            <button type="button" onClick={handleStartCaptureClick}>
              <TiMediaRecord size={20} />
            </button>
          </div>
        </Tooltip>
        <div className="content_action_v">
          <p
            style={{
              fontSize: "clamp(0.8rem, 0.82rem, 0.84rem)",
              paddingRight: "8px",
            }}
          >
            {`00:${counter < 10 ? "0" + counter : counter}`}
          </p>
        </div>
      </div>
      <p
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "clamp(5rem, 5.3rem, 5.35rem)",
          zIndex: "1",
          color: "#ffffff9e",
        }}
      >
        {initialCounter === 4
          ? "Iniciar"
          : initialCounter === 0
            ? null
            : initialCounter}
      </p>
      <Webcam
        videoConstraints={videoConstraints}
        ref={webcamRef}
        audio={true}
        muted
        style={{ transform: "scaleX(-1)" }}
        onUserMediaError={(error: any) => {
          switch (error.name) {
            case "NotAllowedError":
            case "NotFoundError":
            case "NotReadableError":
              setError(t(error.name));
              break;
            default:
              setError(t("errorDefaultVideo"));
              break;
          }
        }}
      />
    </>
  );
};
