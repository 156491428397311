import { useTranslation } from "react-i18next";
import card from "../../assets/icons/id_option.svg";
import { ThirdStepComponent } from "../../utils/interfaces/components.interface";

const UploadCredential = ({ isMobile, onClickHandler }: ThirdStepComponent) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={
          isMobile
            ? "content_main_images_step_mobile"
            : "content_main_images_step"
        }
      >
        <img src={card} alt="selfie" width="auto" height="130px" />
      </div>
      <div
        className={
          isMobile
            ? "content_main_information_step_mobile"
            : "content_main_information_step"
        }
      >
        {/* Information */}
        <div
          className="information_step"
          style={{ marginBottom: isMobile ? "40px" : "0px" }}
        >
          <h3>{t("stepThree")}</h3>
          <span
            className={
              isMobile
                ? "description_information_step_mobile"
                : "description_information_step"
            }
          >
            {t("locateCredentialEmployee")}
          </span>
        </div>
        {/* Actions */}
        <div className="actions_step">
          <button
            type="button"
            className="primary-btn-actions"
            onClick={() => onClickHandler({ type: "upload-bimbo-credential" })}
          >
            {t("continue")}
          </button>
        </div>
      </div>
    </>
  );
};

export default UploadCredential;
