const appState = {
  minHeight: "100dvh",
  identifier: undefined,
  apiKey: undefined,
  popup: false,
  mobile: false,
  onlyCapture: false,
  typeId: "process",
  maxHeight: undefined,
};

export { appState };
