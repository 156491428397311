import { useTranslation } from "react-i18next";
import notFoundIcon from "../../../assets/icons/warning.svg";

const NotFound = ({ isMobile }: { isMobile: boolean }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        isMobile ? "content_mobile_title_process" : "content_title_process"
      }
    >
      <h4>{t("notFoundVerification")}</h4>
      <img
        src={notFoundIcon}
        alt="completed"
        width={isMobile ? "140px" : "90px"}
        height={isMobile ? "140px" : "90px"}
      />
    </div>
  );
};

export default NotFound;
