import { CSSProperties, useEffect, useState } from "react";
import seon from "@seontechnologies/seon-javascript-sdk";

// Components
import NotFound from "../components/UI/NotFound/NotFound";

//Interfaces
import {
  CheckCredentialsInterface,
  CheckImageInterface,
  ClientSdkConfiguration,
  HomeProps,
  OnClickHandlerInterface,
} from "../utils/interfaces";

// Utils
import { getComponent, getDevice, getOptions, getStep } from "../utils/getters";
import Spinner from "../components/UI/Spinner/Spinner";
import vdidLogo from "../assets/VeriDocID_50.png";
import i18next from "i18next";

const Home = ({
  apiKey,
  identifier,
  maxHeight,
  mobile,
  onlyCapture,
  popup,
  typeId,
}: HomeProps) => {
  const [waitOptions, setWaitOptions] = useState(false);
  const [selfieOption, setSelfieOption] = useState<boolean>(false);
  const [verificationOption, setVerificationOption] = useState<boolean>(false);
  const [component, setComponent] = useState<string>("welcome");
  const [style, setStyle] = useState<CSSProperties>();
  const [urlToRedirect, setUrlToRedirect] = useState<string>();
  const [titleEnding, setTitleEnding] = useState<string>("");
  const [finalText, setFinalText] = useState<string>("");
  const [deviceEncryptedPayload, setDeviceEncryptedPayload] =
    useState<string>("");
  const [config, setConfig] = useState<ClientSdkConfiguration>({
    buttonColor: "",
    buttonSecondaryColor: "",
    componentColor: "",
    componentResponsiveColor: "",
    textColor: "",
    textColorTitle: "",
    wallpaperColor: "",
    badgeColor: "",
    cardOptionBorder: "",
    cardOptionBackground: "",
    cardOptionBackgroundActive: "",
    hasCustomProperties: false,
    hideFinalButton: false,
  });
  const [bimboCredentialOption, setBimboCredentialOption] = useState(false);
  const [videoOption, setVideoOption] = useState<boolean>(false);
  const [videoPhrase, setVideoPhrase] = useState<string>("");
  const [status, setStatus] = useState<string>();
  const [globalResult, setGlobalResult] = useState<string>();
  const [time, setTime] = useState<number>(0);

  const [check, setCheck] = useState<CheckImageInterface>({
    image: "",
    currentStep: "",
    nextStep: "",
    attachment: "FRONT",
    identifier: "",
    apiKey: "",
    isDone: false,
    face: false,
  });
  const [checkCredentials, setCheckCredentials] =
    useState<CheckCredentialsInterface>({
      credentials: [],
      currentStep: "",
      identifier: "",
      apiKey: "",
    });

  const isMobile = getDevice() === "mobile";

  useEffect(() => {
    setWaitOptions(true);
    const getLogoSdk = async () => {
      const options = await getOptions(identifier, apiKey, onlyCapture);
      setWaitOptions(false);

      if (options?.language) await i18next.changeLanguage(options?.language);
      if (options?.selfie_option) setSelfieOption(options?.selfie_option);
      if (options?.finalTextSdk) setFinalText(options?.finalTextSdk);
      if (options?.verification_option) {
        setVerificationOption(options?.verification_option);
      }
      if (options?.config?.logo) {
        const customLogo = options?.config?.logo;
        localStorage.setItem("logo_sdk", customLogo);
      } else {
        localStorage.setItem("logo_sdk", vdidLogo);
      }
      if (options?.config) {
        setConfig({
          ...options.config,
          hasCustomProperties: true,
        });
      }
      if (options?.credential_bimbo_scan) {
        setBimboCredentialOption(options.credential_bimbo_scan);
      }
      if (options?.video_option) {
        setVideoPhrase(options?.video_option);
        setVideoOption(true);
      }
    };

    const setStep = async () => {
      const { step, url, status, globalResult } = await getStep(
        identifier,
        apiKey,
        onlyCapture,
        "init",
      );
      if (mobile && !isMobile) {
        setComponent("only-mobile");
      } else {
        setComponent(step);
        setUrlToRedirect(url);
        setStatus(status);
        setGlobalResult(globalResult);

        if (step === "fourth") setStyle({ height: "640px" });
      }
    };
    const getDeviceFingerprint = () => {
      seon.config({
        host: "seondf.com",
        session_id: "9cf66e15-9d97-43a9-8d94-e17970c5be1b",
        audio_fingerprint: true,
        canvas_fingerprint: true,
        webgl_fingerprint: true,
        max_proxy_delay: 1000,
        cname_server: undefined,
        dnsTimeout: 2000,
        font_list: true,
        geolocation: "accurate",
        geolocation_age: 60,
        geolocation_prompt: true,
        similarity_hash: true,
        verbose: true,
        referrer_parameters: true,
        silent_mode: true,
        window_location_length: 128,
        onSuccess: (message) => console.log("success", message),
        onError: (message) => console.log("error", message),
      });
      seon.getBase64Session().then((encryptedPayload) => {
        setDeviceEncryptedPayload(encryptedPayload);
      });
    };

    getDeviceFingerprint();
    getLogoSdk();
    setStep();
  }, [identifier, apiKey, isMobile, mobile, onlyCapture]);

  // Custom styles
  const rootCss: HTMLElement | null = document.querySelector(":root");

  useEffect(() => {
    if (rootCss && config?.hasCustomProperties) {
      // Initial
      const setCss = rootCss?.style;
      const initialBtnp = "#4d53b9";
      const initialBtns = "#4d53b9";
      const initialTitlecolor = "#26285a";
      const initialFontcolor = "#555555";
      const initialWallpaper = "#d6d6d6";
      const initialMaincomponent = "#fff";
      const initialMaincomponentr = "#fff";
      const initialBadgeColor = "#6f65ff";
      const initialAccent = "#333892";
      const initialCardOptionBorder = "#a8acfc";
      const initialCardOptionColor = "#dddffd42";
      const initialCardOptionActiveColor = "#d0d2ffc2";
      // Set properties
      setCss.setProperty(
        "--primary-btn-background",
        `${config.buttonColor ?? initialBtnp}`,
      );
      setCss.setProperty(
        "--secondary-btn-background",
        `${config.buttonSecondaryColor ?? initialBtns}`,
      );
      setCss.setProperty(
        "--font-title",
        `${config.textColorTitle ?? initialTitlecolor}`,
      );
      setCss.setProperty(
        "--font-main-color",
        `${config.textColor ?? initialFontcolor}`,
      );
      setCss.setProperty(
        "--background-app",
        `${config.wallpaperColor ?? initialWallpaper}`,
      );
      setCss.setProperty(
        "--main-component",
        `${config.componentColor ?? initialMaincomponent}`,
      );
      setCss.setProperty(
        "--main-component-responsive",
        `${config.componentResponsiveColor ?? initialMaincomponentr}`,
      );
      setCss.setProperty(
        "--process-badge-background",
        `${config.badgeColor ?? initialBadgeColor}`,
      );
      setCss.setProperty(
        "--blue-accent",
        `${config.textColorCardOption ?? initialAccent}`,
      );
      setCss.setProperty(
        "--card-option-border",
        `${config.cardOptionBorder ?? initialCardOptionBorder}`,
      );
      setCss.setProperty(
        "--card-option-background",
        `${config.cardOptionBackground ?? initialCardOptionColor}`,
      );
      setCss.setProperty(
        "--card-option-background-active",
        `${config.cardOptionBackgroundActive ?? initialCardOptionActiveColor}`,
      );
    }
  }, [rootCss, config]);

  if (!onlyCapture) {
    if (!identifier || !apiKey) return <NotFound isMobile={isMobile} />;
  }

  const onClickHandler = ({ type, opt, refresh }: OnClickHandlerInterface) => {
    if (opt?.checkImage) setCheck(opt.checkImage);
    if (opt?.checkCredentials) setCheckCredentials(opt.checkCredentials);
    if (opt?.titleEnd) setTitleEnding(opt.titleEnd);
    if (opt?.status) setStatus(opt.status);
    if (opt?.globalResult) setGlobalResult(opt.globalResult);
    if (opt?.time) setTime(opt.time);
    if (refresh) {
      window.location.reload();
    } else {
      setComponent(type);

      if (type === "fourth") {
        setStyle({ height: "640px" });
      } else if (type === "video-capture") {
        setStyle({ height: "700px", width: "700px" });
      } else setStyle(undefined);
    }
  };

  return (
    <div className={isMobile ? "App-mobile" : "App-content"} style={style}>
      {waitOptions ? (
        <Spinner />
      ) : (
        <>
          {getComponent({
            component,
            onClickHandler,
            identifier,
            checkImage: check,
            apiKey,
            popup,
            urlToRedirect,
            isMobile,
            titleEnding,
            verificationOption,
            selfieOption,
            finalText,
            onlyCapture,
            typeId,
            config,
            maxHeight,
            bimboCredentialOption,
            checkCredentials,
            deviceEncryptedPayload,
            videoOption,
            videoPhrase,
            status,
            globalResult,
            time,
          })}
        </>
      )}
    </div>
  );
};

export default Home;
