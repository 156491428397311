import { useTranslation } from "react-i18next";

export const LoadingMultipleCapture = ({
  firstImage,
  continueCapture,
}: {
  continueCapture: () => void;
  firstImage: string;
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
      }}
    >
      <div
        style={{
          backgroundImage: `url(data:image/jpeg;base64,${firstImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundClip: "content-box",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          color: "white",
          width: "100%",
          height: "100%",
          display: "grid",
          placeContent: "space-around",
          justifyItems: "center",
          backgroundColor: "#00000087",
          backdropFilter: "blur(2px)",
        }}
      >
        <p>{t("turnDocument")}</p>
        <button
          type="button"
          className="primary-btn-actions"
          onClick={continueCapture}
        >
          {t("continue")}
        </button>
      </div>
    </div>
  );
};
