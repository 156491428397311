import { GET_STEP } from "../constants";
import { ResponseGetStep } from "../interfaces";

const getStep = async (
  identifier: string,
  apiKey: string,
  onlyCapture: boolean,
  nextStep: string,
) => {
  if (onlyCapture) {
    switch (nextStep) {
      case "init":
        return { step: "welcome", url: undefined };
      case "selfie":
        return { step: "second", url: undefined };
      case "finish":
        return { step: "end", url: undefined };
      default:
        return { step: "end", url: undefined };
    }
  }

  try {
    const result = await fetch(`${GET_STEP}/${identifier}`, {
      method: "GET",
      headers: { "x-api-key": apiKey },
    });

    if (result?.ok) {
      const response = await result.json();
      const { step, redirect_url, status, globalResult }: ResponseGetStep =
        response;

      switch (step) {
        case "UPLOAD_DOCUMENT_PHOTO":
          return { step: "welcome", url: redirect_url };
        case "UPLOAD_LIVE_PICTURE":
          return { step: "second", url: redirect_url };
        case "UPLOAD_BIMBO_CREDENTIAL":
          return { step: "third", url: redirect_url };
        case "UPLOAD_VIDEO":
          return { step: "fourth", url: redirect_url, status, globalResult };
        case "PROCESS_VERIFICATION":
          return { step: "end", url: redirect_url };
        default:
          return { step: "end", url: redirect_url };
      }
    }
    return { step: "not-found", url: undefined };
  } catch (error) {
    console.log(error);
    return { step: "not-found", url: undefined };
  }
};

export default getStep;
