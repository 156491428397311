// Assets
import { useEffect } from "react";
import selfie from "../../assets/icons/selfie.svg";
import { VideoRecomendationsComponent } from "../../utils/interfaces/components.interface";
import {
  GlobalResult,
  VerificationStatus,
} from "../../utils/interfaces/config.interface";
import { useTranslation } from "react-i18next";

const VideoRecomendations = ({
  onClickHandler,
  isMobile,
  status,
  globalResult,
}: VideoRecomendationsComponent) => {
  const { t } = useTranslation("");

  useEffect(() => {
    if (
      status === VerificationStatus.Checked &&
      (globalResult === GlobalResult.failed ||
        globalResult === GlobalResult.differentPerson ||
        globalResult === GlobalResult.documentUnacceptable ||
        globalResult === GlobalResult.documentExpired ||
        globalResult === GlobalResult.controlListHits ||
        globalResult === GlobalResult.failedBiometrics ||
        globalResult === GlobalResult.lowImageQuality)
    ) {
      //Verificación rechazada
      onClickHandler({
        type: "information",
        opt: { titleEnd: t("documentError") },
      });
    } else if (
      status === VerificationStatus.ManualChecking ||
      status === VerificationStatus.ManualIdentification ||
      status === VerificationStatus.WaitingChecking
    ) {
      //Verificación en mesa de revisión
      onClickHandler({
        type: "information",
      });
    }
  }, [status, globalResult, onClickHandler, t]);

  const nextStep = async () => {
    onClickHandler({ type: "video-information" });
  };

  return (
    <>
      <div
        className={
          isMobile
            ? "content_main_images_step_mobile"
            : "content_main_images_step"
        }
      >
        <img src={selfie} alt="selfie" width="auto" height="130px" />
      </div>
      <div
        className={
          isMobile
            ? "content_main_information_step_mobile"
            : "content_main_information_step"
        }
      >
        {/* Information */}
        <div
          className="information_step"
          style={{ marginBottom: isMobile ? "40px" : "0px" }}
        >
          <span
            className={
              isMobile
                ? "description_information_step_mobile"
                : "description_information_step"
            }
          >
            <p>{t("videoRecommendationsTitle")}</p>
            <ul
              style={{
                listStyleType: "disc",
                marginLeft: "1.5em",
                fontSize: "clamp(0.78rem, 0.79rem, 0.8rem)",
              }}
            >
              <li>{t("videoRecommendationsOne")}</li>
              <li>{t("videoRecommendationsTwo")}</li>
              <li>{t("videoRecommendationsThree")}</li>
              <li>{t("videoRecommendationsFour")}</li>
              <li>{t("videoRecommendationsFive")}</li>
            </ul>
          </span>
        </div>
        {/* Actions */}
        <div className="actions_step">
          <button
            type="button"
            className="primary-btn-actions"
            onClick={nextStep}
          >
            {t("continue")}
          </button>
        </div>
      </div>
    </>
  );
};

export default VideoRecomendations;
