import { UPLOAD_FILE, Attachment } from "./constants";
import { ImagesInterface } from "./interfaces";

let images: ImagesInterface = {};
const uploadImage = async ({
  apiKey,
  attachment,
  done,
  image,
  onlyCapture,
  uuid,
  deviceEncryptedPayload,
  ocr,
}: {
  apiKey: string;
  attachment: keyof typeof Attachment | "OCR";
  done: boolean;
  image: string;
  onlyCapture: boolean;
  uuid: string;
  deviceEncryptedPayload?: string;
  ocr?: { process?: boolean; scanBarcodes?: boolean };
}) => {
  try {
    let file = image
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "");

    if (onlyCapture) {
      if (attachment === "FRONT") images = { ...images, frontImage: file };
      if (attachment === "BACK") images = { ...images, backImage: file };
      if (attachment === "FACE") images = { ...images, faceImage: file };

      return { images };
    }

    const result = await fetch(UPLOAD_FILE, {
      method: "PUT",
      headers: {
        "x-api-key": apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid,
        attachment,
        file,
        done,
        ...(done && { deviceEncryptedPayload }),
        ...(ocr && { ocr }),
      }),
    });

    const response = await result.json();

    if (!result.ok) return { error: response };

    return {
      step: response?.body?.step,
      images: response?.body?.images,
      status: response?.body?.status,
      globalResult: response?.body?.globalResult,
    };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export default uploadImage;
