import { useEffect, useState } from "react";

// Assets
import vdidLogo from "../assets/VeriDocID_50.png";
import Spinner from "./UI/Spinner/Spinner";
import face from "../assets/icons/face_detection_icon.svg";
import { getOptions } from "../utils/getters";
import { WelcomeComponent } from "../utils/interfaces";
import geolocationClient from "../utils/geolocationClient";
import { useTranslation } from "react-i18next";

const Welcome = ({
  onClickHandler,
  isMobile,
  identifier,
  apiKey,
  onlyCapture,
  typeId,
  custom,
}: WelcomeComponent) => {
  const { t } = useTranslation();
  //Custom design
  const title = custom?.content?.title ?? t("welcomeTitle");
  const text = custom?.content?.text ?? false;
  const showLogo = custom?.content?.logo ?? true;
  const contentPrimaryBtn =
    custom?.buttons?.primary?.text ?? t("continueMobile");
  const contentSecondaryBtn = isMobile
    ? custom?.buttons?.secondary?.mobileText ?? t("continue")
    : custom?.buttons?.secondary?.text ?? t("continue");
  const showPattern = custom?.content?.bgPattern ?? true;

  const [continueProcess, setContinueProcess] = useState<boolean>(true);
  const [messageError, setMessageError] = useState({
    message: "",
    severity: "info",
    visibility: false,
  });

  useEffect(() => {
    const getIp = async () => {
      try {
        const result = await fetch(`https://api.ipify.org/?format=json`, {
          method: "GET",
        });

        const response = await result.json();
        if (result?.ok) {
          setContinueProcess(false);
          setMessageError({
            message: "",
            severity: "info",
            visibility: false,
          });
          return response.ip;
        }
        return response;
      } catch (e: any) {
        return { error: e.name, message: e.message };
      }
    };

    const getGeolocation = async () => {
      try {
        const result = await fetch("https://get.geojs.io/v1/ip/geo.json", {
          method: "GET",
        });

        const response = await result.json();
        if (result?.ok) {
          setContinueProcess(false);
          setMessageError({
            message: "",
            severity: "info",
            visibility: false,
          });
          const { latitude, longitude } = response;
          return { latitude, longitude };
        }
      } catch (e: any) {
        return { error: e.name, message: e.message };
      }
    };

    function geoFindMe() {
      if (!navigator.geolocation) {
        setMessageError({
          message: t("errorBrowserGeo"),
          severity: "err",
          visibility: true,
        });
        return;
      }

      const success = async (position: GeolocationPosition) => {
        setContinueProcess(false);
        setMessageError({
          message: "",
          severity: "info",
          visibility: false,
        });

        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        const ipClient = await getIp();

        if (ipClient.error) {
          setMessageError({
            message: t("errorGettingGeo"),
            severity: "warn",
            visibility: true,
          });
          setContinueProcess(true);
        } else {
          setContinueProcess(false);
          geolocationClient(
            identifier,
            apiKey,
            ipClient,
            latitude,
            longitude,
            onlyCapture,
          );
        }
      };

      const error = async () => {
        setContinueProcess(false);
        setMessageError({
          message: t("errorCaptureGeo"),
          severity: "err",
          visibility: false,
        });

        //Si falla por alguna razón se obtiene la ip y la geolocalización mediante apis para colocar los datos
        const ipClient = await getIp();
        const geoClient = await getGeolocation();
        let lat: number;
        let lng: number;

        if (!geoClient?.error && !ipClient.error) {
          lat = +geoClient?.latitude;
          lng = +geoClient?.longitude;
          geolocationClient(
            identifier,
            apiKey,
            ipClient,
            lat,
            lng,
            onlyCapture,
          );
          setContinueProcess(false);
        } else {
          setMessageError({
            message: t("errorGettingGeo"),
            severity: "warn",
            visibility: true,
          });
          setContinueProcess(true);
        }
      };

      navigator.geolocation.getCurrentPosition(success, error, {
        enableHighAccuracy: true,
      });
    }

    //Permite evaluar si ya tiene una ip capturada que no se ejecute
    const getIpClientWithOptions = async () => {
      const optionsClient = await getOptions(identifier, apiKey, onlyCapture);
      if (optionsClient?.ipClient && optionsClient?.ipClient.length <= 2) {
        geoFindMe();
      }

      setContinueProcess(false);
    };

    getIpClientWithOptions();
  }, [identifier, apiKey, onlyCapture, t]);

  const logoSDK = localStorage.getItem("logo_sdk") ?? vdidLogo;

  return (
    <>
      {!logoSDK ? (
        <Spinner />
      ) : (
        <>
          <div
            className={
              isMobile
                ? !showPattern
                  ? "content_without_pattern"
                  : "content_main_images_step_mobile"
                : !showPattern
                  ? "content_without_pattern"
                  : "content_main_images_step"
            }
          >
            <img
              src={face}
              width="auto"
              height="130px"
              alt="verificación de identidad"
            />
          </div>

          <div
            className={
              isMobile
                ? "content_main_information_step_mobile"
                : "content_main_information_step"
            }
            style={{ height: custom?.extra ? "70%" : "55%" }}
          >
            {/* Information */}
            <div
              className="information_step"
              style={{ marginBottom: isMobile ? "40px" : "0px" }}
            >
              {showLogo && (
                <img
                  src={localStorage.getItem("logo_sdk") as string}
                  alt="SDK"
                  width={"120px"}
                />
              )}
              <div style={{ padding: !showLogo ? "1rem 0 0" : "0" }}>
                <p style={{ fontWeight: "600" }}>{title}</p>
                {text && (
                  <p style={{ fontSize: "0.8rem", paddingTop: "8px" }}>
                    {text}
                  </p>
                )}
              </div>
              {messageError.visibility ? (
                <span className={`error_sdk ${messageError.severity}`}>
                  {messageError.message}
                </span>
              ) : null}
            </div>
            {/* Extra information */}
            {custom?.extra && !isMobile && (
              <div>
                {custom?.extra?.text && (
                  <p style={{ fontSize: "0.82rem", padding: "0 1rem" }}>
                    {custom?.extra?.text}
                  </p>
                )}
                {custom?.extra?.title && (
                  <h5 style={{ paddingTop: "10px" }}>{custom?.extra?.title}</h5>
                )}
              </div>
            )}

            {/* Actions */}
            <div
              className={
                isMobile
                  ? "actions_step_two_columns_mobile"
                  : "actions_step_two_columns"
              }
            >
              {isMobile || onlyCapture ? null : (
                <button
                  type="button"
                  style={custom?.buttons?.styles}
                  className="secondary-btn-actions"
                  disabled={continueProcess}
                  onClick={() => onClickHandler({ type: "send-mobile" })}
                >
                  {contentPrimaryBtn}
                </button>
              )}
              <button
                type="button"
                style={{
                  ...custom?.buttons?.styles,
                  ...(isMobile && custom?.buttons?.mobileStyle),
                }}
                className="primary-btn-actions"
                disabled={continueProcess}
                onClick={() => onClickHandler({ type: typeId })}
              >
                {contentSecondaryBtn}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Welcome;
