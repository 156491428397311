import { useTranslation } from "react-i18next";
import onlyMobileIcon from "../../../assets/icons/warning.svg";
const OnlyMobile = () => {
  const { t } = useTranslation();

  return (
    <div className="content_mobile_title_process">
      <h4 style={{ width: "80%", margin: "0 auto 1.4rem" }}>
        {t("onlyMobile")}
      </h4>
      <img
        src={onlyMobileIcon}
        alt="only mobile"
        width="100px"
        height="100px"
      />
    </div>
  );
};

export default OnlyMobile;
