import { useCallback, useState } from "react";
import { blobToBase64String } from "blob-util";
import {
  dispatchControlEvent,
  DocumentCustomEvent,
  ControlEventInstruction,
} from "@innovatrics/dot-document-auto-capture/events";
import { LoadingMultipleCapture } from "../UI/Capture";
import warningIcon from "../../assets/icons/warning.svg";
import { UploadCredentialsStepComponent } from "../../utils/interfaces";
import { DocumentCallback, DocumentCamera, DocumentUi } from "../UI/Camera";
import { useTranslation } from "react-i18next";

const CaptureCredential = ({
  isMobile,
  apiKey,
  currentStep,
  identifier,
  nextStep,
  onClickHandler,
}: UploadCredentialsStepComponent) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [imagesCaptured, setImagesCaptured] = useState<string[]>([]);
  const [hints, setHints] = useState<{
    capture: string;
    title: string;
    description: string;
    showLoading: boolean;
  }>({
    capture: "",
    title: t("bimboFront"),
    description: t("locateBimboFront"),
    showLoading: false,
  });

  const handleDocumentPhoto: DocumentCallback = async (
    { image, data },
    content,
  ) => {
    await blobToBase64String(image)
      .then((img) => {
        if (img) {
          const credentials = [...imagesCaptured, img];
          setImagesCaptured(credentials);

          if (credentials.length === 1) {
            setHints({
              capture: img,
              title: t("back"),
              description: t("locateBimboBack"),
              showLoading: true,
            });
          }

          if (credentials.length === 2) {
            onClickHandler({
              type: "check-credential",
              opt: {
                checkCredentials: {
                  credentials,
                  currentStep,
                  identifier,
                  apiKey,
                },
              },
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleErrorPhoto = useCallback((error: Error) => {
    if (error) setIsError(true);
  }, []);

  const continueDetection = () => {
    dispatchControlEvent(
      DocumentCustomEvent.CONTROL,
      ControlEventInstruction.CONTINUE_DETECTION,
    );
  };

  const multipleCapture = () => {
    continueDetection();
    setHints({
      capture: "",
      title: t("back"),
      description: t("locateBimboBack"),
      showLoading: false,
    });
  };

  return (
    <>
      <div
        className={
          isMobile ? "content_mobile_title_process" : "content_title_process"
        }
      >
        <h4>{hints.title}</h4>
        <p>{hints.description}</p>
      </div>
      <div
        className={
          isMobile ? "UploadImageStep-Webcam__mobile" : "UploadImageStep-Webcam"
        }
      >
        <div
          className="content_webcam_client"
          style={{ display: isError ? "none" : "block" }}
        >
          <DocumentCamera
            wasmDirectoryPath="https://pdfsignfiles.blob.core.windows.net/files-sdk/wasm_v_5.2.8"
            cameraFacing="environment"
            onPhotoTaken={handleDocumentPhoto}
            onError={handleErrorPhoto}
            thresholds={{
              brightnessHighThreshold: 0.85,
              brightnessLowThreshold: 0.6,
              hotspotsScoreThreshold: 0.1,
              confidenceThreshold: 0.9,
              sizeSmallThreshold: isMobile ? 0.38 : 0.43,
              sharpnessThreshold: isMobile ? 0.8 : 0.7,
              outOfBoundsThreshold: 0.03,
            }}
          />
          <DocumentUi
            placeholder="id-dash-rounded"
            showCameraButtons={true}
            instructions={{
              document_too_far: t("tooFar"),
              document_centering: t("documentCentering"),
              hotspots_present: t("hotspotsPresent"),
              brightness_too_high: t("brightnessTooHigh"),
              brightness_too_low: t("brightnessTooLow"),
              sharpness_too_low: t("sharpnessTooLow"),
              candidate_selection: t("candidateSelection"),
              document_not_present: t("documentNotPresent"),
            }}
            theme={{
              colors: {
                placeholderColor: "#cecece", //Color of the placeholder lines
                instructionColor: "#cecece", // Instruction background color
                instructionTextColor: "#2A2550", //Instruction text color
                instructionColorSuccess: "#55D8C1", //Instruction background color when all validations are passed
                placeholderColorSuccess: "#55D8C1", //Color of the placeholder lines when all validations are passed
              },
            }}
            appStateInstructions={{
              loading: { text: t("loading"), visible: true },
              waiting: { visible: false },
            }}
          />
          {/* Loading */}
          {hints.showLoading && (
            <LoadingMultipleCapture
              firstImage={hints.capture}
              continueCapture={() => multipleCapture()}
            />
          )}
        </div>
        <div
          className="content_error_camera"
          style={{ display: isError ? "flex" : "none" }}
        >
          <img
            src={warningIcon}
            alt="completed"
            width={isMobile ? "65px" : "50px"}
            height={isMobile ? "65px" : "50px"}
            style={{ margin: "0 0 1.5rem" }}
          />
          <p
            style={{
              margin: "0 0 1.5rem",
              textAlign: "center",
            }}
          >
            {t("errorCamera")}
          </p>
          <button
            type="button"
            className="primary-btn-actions"
            onClick={() => onClickHandler({ type: "", refresh: true })}
          >
            {t("tryAgain")}
          </button>
        </div>
      </div>
    </>
  );
};

export default CaptureCredential;
