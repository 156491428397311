import "./return.css";
import Arrow from "../../../assets/icons/arrow.png";
import { useTranslation } from "react-i18next";

export const Return = ({
  handleAction,
  isMobile,
  isProcess,
}: {
  handleAction: () => void;
  isMobile: boolean;
  isProcess: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className={isMobile ? "content_mobile_return" : "content_return"}>
      {isMobile ? (
        <button type="button" onClick={handleAction}>
          <img src={Arrow} alt="return" />
        </button>
      ) : null}
      {isProcess ? (
        <h5 className="proceso_title_badge">{t("process")}</h5>
      ) : null}
    </div>
  );
};
