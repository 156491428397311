import { GEOLOCATION_SDK } from "./constants";

const geolocationClient = async (
  identifier: string,
  apiKey: string,
  ip: string,
  lat: number,
  lng: number,
  onlyCapture: boolean,
) => {
  if (onlyCapture) return;
  try {
    await fetch(GEOLOCATION_SDK, {
      method: "PUT",
      headers: {
        "x-api-key": apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier,
        ip,
        latitude: String(lat),
        longitude: String(lng),
      }),
    });
  } catch (error) {
    console.log(error);
    return { step: "not-found", url: undefined };
  }
};

export default geolocationClient;
