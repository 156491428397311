import "./WaitingStep.css";

// Assets
import engineBlue from "../../assets/icons/settings-blue.svg";
import engineOrange from "../../assets/icons/settings-orange.svg";
import { useTranslation } from "react-i18next";

const WaitingStep = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="WaitingStep-content">
        <p className="WaitingStep-p">{t("processVerification")}</p>
      </div>
      <div className="WaitingStep-content-image">
        <div className="WaitingStep-image">
          <img
            className="WaitingStep-img-bl1"
            src={engineBlue}
            alt="engineBl"
          />
          <img
            className="WaitingStep-img-bl2"
            src={engineBlue}
            alt="engineBl"
          />
          <img
            className="WaitingStep-img-or1"
            src={engineOrange}
            alt="engineOr"
          />
          <img
            className="WaitingStep-img-or2"
            src={engineOrange}
            alt="engineOr"
          />
        </div>
      </div>
    </>
  );
};

export default WaitingStep;
