import { useState } from "react";
import Spinner from "../UI/Spinner/Spinner";
import uploadImage from "../../utils/uploadImage";
import { getDevice } from "../../utils/getters";
import { CheckImageStepComponent } from "../../utils/interfaces";
import { useTranslation } from "react-i18next";

const CheckImageStep = ({
  onClickHandler,
  backStep,
  nextStep,
  attachment,
  identifier,
  onlyCapture,
  selfieOption,
  stepsDone,
  apiKey,
  isDone = false,
  image,
  face = false,
  text,
  title,
  customDocument,
  deviceEncryptedPayload,
}: CheckImageStepComponent) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //IsMobile - para indicar si es mobile cambia de estilos en la imagen
  const isMobile = getDevice() === "mobile";

  const sendImage = async () => {
    try {
      setIsLoading(true);
      const { step, error, images, status, globalResult } = await uploadImage({
        image,
        attachment,
        done: isDone,
        uuid: identifier,
        apiKey,
        onlyCapture,
        deviceEncryptedPayload,
      });

      if (step === "PROCESS_VERIFICATION") {
        if (images) window.postMessage(images);

        // -------------- Final step
        onClickHandler({
          type: "end",
          opt: {
            titleEnd: t("dataSendSuccess"),
          },
        });
      } else if (
        onlyCapture &&
        !selfieOption &&
        (stepsDone.includes("id-back") || stepsDone.includes("passport"))
      ) {
        window.parent.postMessage({ images }, { targetOrigin: "*" });

        onClickHandler({
          type: "end",
          opt: {
            titleEnd: t("dataSendSuccess"),
          },
        });
      } else if (error) {
        onClickHandler({ type: "error" });
      } else {
        onClickHandler({
          type: nextStep,
          opt: { status: status, globalResult: globalResult },
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const retakeImage = () => onClickHandler({ type: backStep });

  let list: string[] | null = null;
  if (text.includes("-")) {
    if (text.split("-") && text.split("-").length > 0) {
      list = text.split("-");
    }
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div
        className={
          isMobile ? "content_mobile_check_process" : "content_title_process"
        }
      >
        <h4>{title}</h4>
        {list !== null && list.length > 0 ? (
          <ul style={{ textAlign: "left" }}>
            {list.map(
              (item, i) =>
                item !== "" && (
                  <li
                    key={`li_${item}_${i}`}
                    style={{ fontSize: "clamp(0.78rem, 0.79rem, 0.82rem)" }}
                  >
                    {item}
                  </li>
                ),
            )}
          </ul>
        ) : (
          <p>{text}</p>
        )}
      </div>
      <div
        className={
          isMobile ? "content_result_image_mobile" : "content_result_image"
        }
      >
        <img src={image} alt="captura" className={face ? "face" : "capture"} />
      </div>
      {/* Actions */}
      <div
        className={
          isMobile
            ? "actions_step_two_columns_mobile"
            : "actions_step_two_columns"
        }
        style={{ textAlign: "center" }}
      >
        <button
          type="button"
          style={customDocument?.buttons?.styles}
          className="secondary-btn-actions"
          onClick={retakeImage}
        >
          {t("takeAgain")}
        </button>
        <button
          type="button"
          style={customDocument?.buttons?.styles}
          className="primary-btn-actions"
          onClick={sendImage}
        >
          {t("continue")}
        </button>
      </div>
    </>
  );
};

export default CheckImageStep;
