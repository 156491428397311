import { useState } from "react";
import { WebVerification } from "vdid-sdk-web";
import Spinner from "../UI/Spinner/Spinner";
import vdidLogo from "../../assets/VeriDocID_50.png";
// Email
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";
import { SendEmailStepComponent } from "../../utils/interfaces";
import { useTranslation } from "react-i18next";

const SendEmailStep = ({
  onClickHandler,
  identifier,
  apiKey,
  isMobile,
  custom,
}: SendEmailStepComponent) => {
  const { t } = useTranslation();
  const showPattern = custom?.content?.bgPattern ?? true;
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //Error
  const [textEmailError, setTextEmailError] = useState<string>("");
  const [visibleMsgEmailError, setVisibleMsgEmailError] = useState("none");
  //SDK
  const vdid = new WebVerification(apiKey);

  const onChangeValueEmail = (value: string) => {
    if (value.length > 0) {
      setIsEmailValid(true);
      setEmail(value);
      //Error
      setVisibleMsgEmailError("none");
    } else {
      setIsEmailValid(false);
      //Error
      setVisibleMsgEmailError("block");
      setTextEmailError(t("typeEmail"));
    }
  };

  const onSendEmailHandler = async () => {
    if (email.length > 5) {
      setIsLoading(true);

      const response = await vdid.verifyIdentityMobile(identifier, {
        method: "email",
        email,
      });

      if (response === "Email sent") {
        setIsLoading(false);
        onClickHandler({
          type: "end",
          opt: {
            titleEnd: t("emailSendSuccess"),
          },
        });
        setVisibleMsgEmailError("none");
      } else {
        setVisibleMsgEmailError("block");
        setTextEmailError(t("emailSendError"));
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      //Error
      setVisibleMsgEmailError("block");
      setTextEmailError(t("typeValidEmail"));
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div
        className={
          isMobile
            ? "content_main_images_step_mobile"
            : !showPattern
              ? "content_without_pattern"
              : "content_main_images_step"
        }
      >
        <img
          src={localStorage.getItem("logo_sdk") ?? vdidLogo}
          alt="SDK"
          width={"120px"}
        />
      </div>
      {/* Information */}
      <div
        className={
          isMobile
            ? "content_main_information_step_mobile"
            : "content_main_information_step"
        }
      >
        <div
          className="information_step"
          style={{ marginBottom: isMobile ? "40px" : "0px" }}
        >
          <span
            className={
              isMobile
                ? "description_information_step_mobile"
                : "description_information_step"
            }
          >
            {custom?.content?.text ?? t("typeYourEmail")}
          </span>
          <div
            style={{
              width: "90%",
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p style={{ fontSize: "0.78rem" }}>
              <strong>{t("email")}</strong>
            </p>
            <MaskedInput
              keepCharPositions={false}
              mask={emailMask}
              guide={true}
              placeholder="ejemplo@email.com"
              style={{
                outline: "none",
                width: "100%",
                border: "1px solid #cecece",
                borderRadius: "4px",
                padding: "0.6rem 0 0.6rem 0.7rem",
                margin: "0px 0 10px",
              }}
              onChange={(event) => onChangeValueEmail(event.target.value)}
            />
            <span
              className="error_sdk err"
              style={{
                display: `${visibleMsgEmailError}`,
              }}
            >{`${textEmailError}`}</span>
          </div>
        </div>
        {/* Actions */}
        <div
          className={
            isMobile
              ? "actions_step_two_columns_mobile"
              : "actions_step_two_columns"
          }
        >
          <button
            type="button"
            style={custom?.buttons?.styles}
            className="secondary-btn-actions"
            onClick={() => onClickHandler({ type: "welcome" })}
          >
            {t("goBack")}
          </button>
          <button
            type="button"
            style={custom?.buttons?.styles}
            className="primary-btn-actions"
            onClick={onSendEmailHandler}
            disabled={!isEmailValid}
          >
            {t("send")}
          </button>
        </div>
      </div>
    </>
  );
};

export default SendEmailStep;
