import { SDKcustom } from "../../utils/interfaces/config.interface";

export const ProcessStep = ({
  isMobile,
  sourceImage,
  stepNumber,
  titleStep,
  contentStep,
  custom,
}: {
  isMobile: boolean;
  sourceImage: string;
  stepNumber: string;
  titleStep: string;
  contentStep: string;
  custom?: SDKcustom;
}) => {
  const showPattern = custom?.content?.bgPattern ?? true;
  return (
    <div style={{ height: "100%" }}>
      <div
        className={
          isMobile
            ? !showPattern
              ? "content_without_pattern"
              : "content_main_images_step_mobile"
            : !showPattern
              ? "content_without_pattern"
              : "content_main_images_step"
        }
      >
        <img src={sourceImage} alt="identificación" width={110} height={90} />
      </div>
      <div
        className={
          isMobile
            ? "content_main_information_step_mobile"
            : "content_main_information_step"
        }
        style={{ borderRadius: "0" }}
      >
        <div
          className="information_step"
          style={{ marginBottom: isMobile ? "40px" : "0px" }}
        >
          <h4>{stepNumber}</h4>
          <p
            style={{
              fontSize: "clamp(0.96rem, 0.99rem, 1.1rem)",
              color: "var(--font-title)",
              fontWeight: "600",
            }}
          >
            {titleStep}
          </p>
          <span
            className={
              isMobile
                ? "description_information_step_mobile"
                : "description_information_step"
            }
            style={{ padding: "10px 0 20px" }}
          >
            {contentStep}
          </span>
        </div>
      </div>
    </div>
  );
};
