// Styles
import "./UploadSelfieStep.css";
// Utils
import { EyeLiveness } from "./EyeLiveness";
import { UploadSelfieStepComponent } from "../../utils/interfaces";
import { useTranslation } from "react-i18next";

const UploadSelfieStep = ({
  onClickHandler,
  nextStep,
  attachment,
  identifier,
  apiKey,
  currentStep,
  isMobile,
  maxHeight,
  cameraFullScreenMobile,
}: UploadSelfieStepComponent) => {
  const { t } = useTranslation();

  return (
    <div
      className={isMobile && cameraFullScreenMobile ? "fullscreen_mode" : ""}
    >
      <div
        className={
          isMobile
            ? cameraFullScreenMobile
              ? "fullscreen_title_process selfie"
              : "content_mobile_title_process"
            : "content_title_process"
        }
      >
        <h4>{t("captureFace")}</h4>
        <p>{t("locateFace")}</p>
      </div>
      <div
        className={
          isMobile
            ? cameraFullScreenMobile
              ? "UploadSelfie_fullscreen"
              : "UploadSelfie-Webcam__mobile"
            : "UploadSelfie-Webcam"
        }
      >
        {/* <FaceLiveness
            apiKey={apiKey}
            isMobile={isMobile}
            nextStep={nextStep}
            maxHeight={maxHeight}
            identifier={identifier}
            attachment={attachment}
            currentStep={currentStep}
            onClickHandler={onClickHandler}
          /> */}
        <EyeLiveness
          apiKey={apiKey}
          isMobile={isMobile}
          nextStep={nextStep}
          maxHeight={maxHeight}
          identifier={identifier}
          attachment={attachment}
          currentStep={currentStep}
          onClickHandler={onClickHandler}
        />
      </div>
    </div>
  );
};

export default UploadSelfieStep;
