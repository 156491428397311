import { CSSProperties, useState } from "react";
import idCard from "../../assets/icons/id_option.svg";
import { FirstStepComponent } from "../../utils/interfaces";
import { Return } from "../UI/Return/Return";
import { useTranslation } from "react-i18next";

const FirstStep = ({
  custom,
  onClickHandler,
  isMobile,
  verificationOption,
}: FirstStepComponent) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState<string>("");

  const title = custom?.content?.title ?? t("stepOne");
  const text = custom?.content?.text ?? t("selectDocumentType");
  const showPattern = custom?.content?.bgPattern ?? true;
  const flexDirection = custom?.content?.seletcTypeIdColumn
    ? "column"
    : undefined;
  const styleInput: CSSProperties | undefined = custom?.content
    ?.seletcTypeIdColumn
    ? {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        alignItems: "center",
        width: "16rem",
        height: "3rem",
        marginBottom: "1rem",
      }
    : undefined;

  const onChangeCheckmarkHandler = (type: string) => setUrl(type);

  return (
    <>
      <Return
        handleAction={() => onClickHandler({ type: "process" })}
        isMobile={isMobile}
        isProcess={false}
      />
      <div
        className={
          isMobile
            ? !showPattern
              ? "content_without_pattern"
              : "content_main_images_step_mobile"
            : !showPattern
              ? "content_without_pattern"
              : "content_main_images_step"
        }
      >
        <img src={idCard} alt="identificación" width={110} height={90} />
      </div>
      <div
        className={
          isMobile
            ? "content_main_information_step_mobile"
            : "content_main_information_step"
        }
      >
        {/* Information */}
        <div
          className="information_step"
          style={{ marginBottom: isMobile ? "1px" : "0px" }}
        >
          <h4>{title}</h4>
          <span
            className={
              isMobile
                ? "description_information_step_mobile"
                : "description_information_step"
            }
          >
            {text}
          </span>
          {/* Options */}
          <div
            className="content_main_options_step"
            style={{ marginTop: isMobile ? "3rem" : "1rem", flexDirection }}
          >
            <div className="card_option_step" style={{ ...styleInput }}>
              <input
                type="radio"
                name="option_step"
                id="option_step"
                onChange={() => onChangeCheckmarkHandler("id")}
              />
              <div className="info_card_option_step">
                <h6>{t("id")}</h6>
              </div>
            </div>

            {!verificationOption ? (
              <div className="card_option_step" style={{ ...styleInput }}>
                <input
                  type="radio"
                  name="option_step"
                  id="option_step"
                  onChange={() => onChangeCheckmarkHandler("passport")}
                />
                <div className="info_card_option_step">
                  <h6>{t("passportTitle")}</h6>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* Actions */}
      <div
        className={
          isMobile
            ? "actions_step_two_columns_mobile"
            : "actions_step_two_columns"
        }
        style={{ textAlign: "center" }}
      >
        {isMobile ? null : (
          <button
            type="button"
            style={custom?.buttons?.styles}
            className="secondary-btn-actions"
            onClick={() => onClickHandler({ type: "process" })}
          >
            {t("goBack")}
          </button>
        )}
        <button
          type="button"
          style={{
            ...custom?.buttons?.styles,
            ...(isMobile && custom?.buttons?.mobileStyle),
          }}
          className="primary-btn-actions"
          disabled={url === ""}
          onClick={() => onClickHandler({ type: url })}
        >
          {t("continue")}
        </button>
      </div>
    </>
  );
};

export default FirstStep;
