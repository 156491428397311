import notFoundIcon from "../../../assets/icons/warning.svg";
import { InformationComponent } from "../../../utils/interfaces/components.interface";

const Information = ({ isMobile, titleEnding }: InformationComponent) => {
  return (
    <div
      className={
        isMobile ? "content_mobile_title_process" : "content_title_process"
      }
    >
      <h4>
        {titleEnding
          ? titleEnding
          : "Su verificación esta siendo revisado por nuestra mesa de expertos, recargue la página y revise si ya fue liberada."}
      </h4>
      <img
        src={notFoundIcon}
        alt="completed"
        width={isMobile ? "140px" : "90px"}
        height={isMobile ? "140px" : "90px"}
        style={{ margin: "0 0 1.5rem" }}
      />
    </div>
  );
};

export default Information;
