import {
  MagnifEyeLiveness,
  MagnifEyeLivenessUi,
  OnCompleteData,
} from "../UI/Camera";
import { useCallback } from "react";
import { blobToBase64String } from "blob-util";
import { PropsLiveness } from "../../utils/interfaces/components.interface";
import { t } from "i18next";

export const EyeLiveness = ({
  apiKey,
  nextStep,
  attachment,
  identifier,
  maxHeight,
  currentStep,
  onClickHandler,
}: PropsLiveness) => {
  // Selfie
  const handleOnComplete = async (
    data: OnCompleteData,
    content: Uint8Array,
  ) => {
    // Default mimetype image/jpeg
    await blobToBase64String(data?.image)
      .then((img) =>
        onClickHandler({
          type: "check",
          opt: {
            checkImage: {
              image: img ? `data:image/jpeg;base64,${img}` : "",
              currentStep,
              nextStep,
              attachment,
              identifier,
              apiKey,
              isDone: true,
              face: true,
            },
          },
        }),
      )
      .catch((err) => console.log(err));
  };

  //   Error
  const handleError = useCallback((error: Error) => {
    if (error) alert(t("allowUseCamera"));
  }, []);

  return (
    <div style={{ position: "relative", overflow: "hidden", maxHeight }}>
      <MagnifEyeLiveness
        wasmDirectoryPath="https://pdfsignfiles.blob.core.windows.net/files-sdk/wasm_v_5.2.8"
        onComplete={handleOnComplete}
        onError={handleError}
      />
      <MagnifEyeLivenessUi
        theme={{
          colors: {
            placeholderColor: "#cecece", //Color of the placeholder lines
            instructionColor: "#cecece", // Instruction background color
            instructionTextColor: "#2A2550", //Instruction text color
            instructionColorSuccess: "#55D8C1", //Instruction background color when all validations are passed
            placeholderColorSuccess: "#55D8C1", //Color of the placeholder lines when all validations are passed
          },
        }}
        instructions={{
          face_too_close: t("tooClose"),
          face_too_far: t("tooFar"),
          face_centering: t("faceCentering"),
          face_not_present: t("faceNotPresent"),
          brightness_too_high: t("brightnessTooHigh"),
          brightness_too_low: t("brightnessTooLow"),
          sharpness_too_low: t("sharpnessTooLow"),
          candidate_selection: t("candidateSelection"),
          fit_your_eye: t("fitYourEye"),
          device_pitched: t("devicePitched"),
        }}
        appStateInstructions={{
          loading: { text: t("loading"), visible: true },
          done: { visible: false },
        }}
        showCameraButtons
      />
    </div>
  );
};
