// Autocapture
import { DocumentCamera, DocumentUi, DocumentCallback } from "../UI/Camera";
// Styles
import "./UploadImageStep.css";
// Utils
import { useCallback, useState } from "react";
import { blobToBase64String } from "blob-util";
import warningIcon from "../../assets/icons/warning.svg";
import { UploadImageStepComponent } from "../../utils/interfaces";
import { useTranslation } from "react-i18next";

const UploadImageStep = ({
  onClickHandler,
  title,
  nextStep,
  attachment,
  identifier,
  apiKey,
  isDone = false,
  currentStep,
  isMobile,
  text,
  errorText,
  maxHeight,
  properties,
  cameraFullScreenMobile,
}: UploadImageStepComponent) => {
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  const handleDocumentPhoto: DocumentCallback = async (
    { image, data },
    content,
  ) => {
    await blobToBase64String(image)
      .then((img) =>
        onClickHandler({
          type: "check",
          opt: {
            checkImage: {
              image: img ? `data:image/jpeg;base64,${img}` : "",
              currentStep,
              nextStep,
              attachment,
              identifier,
              apiKey,
              isDone,
            },
          },
        }),
      )
      .catch((err) => console.log(err));
  };

  const handleErrorPhoto = useCallback((error: Error) => {
    if (error) {
      console.log(error);
      setIsError(true);
    }
  }, []);

  return (
    <div
      className={isMobile && cameraFullScreenMobile ? "fullscreen_mode" : ""}
    >
      <div
        className={
          isMobile
            ? cameraFullScreenMobile
              ? "fullscreen_title_process"
              : "content_mobile_title_process"
            : "content_title_process"
        }
      >
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
      <div
        className={
          isMobile
            ? cameraFullScreenMobile
              ? "UploadImageStep_fullscreen"
              : "UploadImageStep-Webcam__mobile"
            : "UploadImageStep-Webcam"
        }
      >
        <div
          className="content_webcam_client"
          style={{ display: isError ? "none" : "block", maxHeight }}
        >
          <DocumentCamera
            wasmDirectoryPath="https://pdfsignfiles.blob.core.windows.net/files-sdk/wasm_v_5.2.8"
            cameraFacing="environment"
            onPhotoTaken={handleDocumentPhoto}
            onError={handleErrorPhoto}
            thresholds={{
              brightnessHighThreshold: properties?.brightnessHigh ?? 0.85,
              brightnessLowThreshold: properties?.brightnessLow ?? 0.6,
              hotspotsScoreThreshold: properties?.hotspotsScore ?? 0.1,
              confidenceThreshold: properties?.confidence ?? 0.9,
              sizeSmallThreshold: isMobile
                ? 0.38
                : properties?.sizeSmall ?? 0.43,
              sharpnessThreshold: isMobile ? 0.8 : properties?.sharpness ?? 0.7,
              outOfBoundsThreshold: properties?.outOfBounds ?? 0.03,
            }}
          />
          <DocumentUi
            placeholder="id-dash-rounded"
            showCameraButtons={!cameraFullScreenMobile}
            instructions={{
              document_too_far: t("tooFar"),
              document_centering: t("documentCentering"),
              hotspots_present: t("hotspotsPresent"),
              brightness_too_high: t("brightnessTooHigh"),
              brightness_too_low: t("brightnessTooLow"),
              sharpness_too_low: t("sharpnessTooLow"),
              candidate_selection: t("candidateSelection"),
              document_not_present: t("documentNotPresent"),
            }}
            theme={{
              colors: {
                placeholderColor: "#cecece", //Color of the placeholder lines
                instructionColor: "#cecece", // Instruction background color
                instructionTextColor: "#2A2550", //Instruction text color
                instructionColorSuccess: "#55D8C1", //Instruction background color when all validations are passed
                placeholderColorSuccess: "#55D8C1", //Color of the placeholder lines when all validations are passed
              },
            }}
            appStateInstructions={{
              loading: { text: t("loading"), visible: true },
              waiting: { visible: false },
            }}
          />
        </div>
        <div
          className="content_error_camera"
          style={{ display: isError ? "flex" : "none" }}
        >
          <img
            src={warningIcon}
            alt="completed"
            width={isMobile ? "65px" : "50px"}
            height={isMobile ? "65px" : "50px"}
            style={{ margin: "0 0 1.5rem" }}
          />
          <p
            style={{
              margin: "0 0 1.5rem",
              textAlign: "center",
              color:
                isMobile && cameraFullScreenMobile
                  ? "#fff"
                  : "var(--font-main-color)",
            }}
          >
            {errorText}
          </p>
          <button
            type="button"
            className="primary-btn-actions"
            onClick={() => onClickHandler({ type: "", refresh: true })}
          >
            {t("tryAgain")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadImageStep;
