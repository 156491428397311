import Welcome from "../../components/Welcome";
import FirstStep from "../../components/FirstStep/FirstStep";
import FinalStep from "../../components/FinalStep/FinalStep";
import SecondStep from "../../components/SecondStep/SecondStep";
import WaitingStep from "../../components/WaitingStep/WaitingStep";
import { GetComponentInterface } from "../interfaces";
import CheckImageStep from "../../components/CheckImageStep/CheckImageStep";
import NotFound from "../../components/UI/NotFound/NotFound";
import SendEmailStep from "../../components/SendEmailStep/SendEmailStep";
import OnlyMobile from "../../components/UI/OnlyMobile/OnlyMobile";
import Process from "../../components/ProcessQA/Process";

// V2
import UploadImageStep from "../../components/UploadImageStep/UploadImageStep";
import UploadSelfieStep from "../../components/UploadSelfieStep/UploadSelfieStep";
import Error from "../../components/UI/Error/Error";
import Information from "../../components/UI/Information/Information";
import {
  CaptureCredential,
  CheckCredential,
  UploadCredential,
} from "../../components/BimboCredentialsStep";
import VideoRecomendations from "../../components/VideoStep/VideoRecomendations";
import VideoInformation from "../../components/VideoStep/VideoInformation";
import VideoCapture from "../../components/VideoStep/VideoCapture";
import { t } from "i18next";

const stepsDone: Array<string> = [];
const getComponent = ({
  component,
  onClickHandler,
  identifier,
  checkImage,
  apiKey,
  popup,
  urlToRedirect,
  isMobile,
  titleEnding,
  selfieOption,
  verificationOption,
  finalText,
  onlyCapture,
  typeId,
  config,
  maxHeight,
  bimboCredentialOption,
  checkCredentials,
  deviceEncryptedPayload,
  videoOption,
  videoPhrase,
  status,
  globalResult,
  time,
}: GetComponentInterface): JSX.Element => {
  const {
    cameraFullScreenMobile = false,
    errorCameraText,
    custom,
    hideFinalButton,
    properties,
  } = config;

  const isId = typeId === "id";

  // Upload images
  const backText = custom?.back?.content?.text ?? t("uploadContent");
  const backTitle = custom?.back?.content?.title ?? t("back");
  const frontText = custom?.front?.content?.text ?? t("uploadContent");
  const frontTitle = custom?.front?.content?.title ?? t("front");
  const passportText = custom?.passport?.content?.text ?? t("uploadContent");
  const passportTitle = custom?.passport?.content?.title ?? t("passport");
  // Check images
  const checkContent = isId
    ? custom?.checkId?.content?.text
    : custom?.checkPassport?.content?.text;
  const checkTitle =
    custom?.checkId?.content?.title ??
    custom?.checkPassport?.content?.title ??
    t("checkTitle");
  const checkText = checkContent ?? t("checkContent");
  // Tips
  const firstTitleStep = isId
    ? custom?.processId?.content?.title
    : custom?.processPassport?.content?.title;
  const firstStepText =
    custom?.processId?.content?.text ??
    custom?.processPassport?.content?.text ??
    t("firsStepContent");
  const firstStepTitle = firstTitleStep ?? t("firstStepTitle");
  // Error
  const errorText = errorCameraText ?? t("errorCamera");
  const nextStepAfterDocumentUpload =
    videoOption && !bimboCredentialOption && !selfieOption
      ? "fourth"
      : bimboCredentialOption && !selfieOption
        ? "third"
        : selfieOption
          ? "second"
          : "end";

  stepsDone.push(component);

  switch (component) {
    case "welcome":
      return (
        <Welcome
          onClickHandler={onClickHandler}
          isMobile={isMobile}
          identifier={identifier}
          apiKey={apiKey}
          onlyCapture={onlyCapture}
          typeId={typeId}
          custom={custom?.welcome}
        />
      );
    case "process":
      return (
        <Process
          onClickHandler={onClickHandler}
          isMobile={isMobile}
          selfieOption={selfieOption}
          firstStepText={firstStepText}
          firstStepTitle={firstStepTitle}
          custom={custom?.processId || custom?.processPassport}
          bimboCredentialOption={bimboCredentialOption}
          videoOption={videoOption}
        />
      );
    case "first":
      return (
        <FirstStep
          onClickHandler={onClickHandler}
          isMobile={isMobile}
          verificationOption={verificationOption}
          custom={custom?.documentStep}
        />
      );
    case "id":
      return (
        <UploadImageStep
          onClickHandler={onClickHandler}
          title={frontTitle}
          nextStep="id-back"
          attachment="FRONT"
          identifier={identifier}
          apiKey={apiKey}
          currentStep={component}
          isMobile={isMobile}
          text={frontText}
          errorText={errorText}
          maxHeight={maxHeight}
          properties={properties}
          cameraFullScreenMobile={cameraFullScreenMobile}
        />
      );
    case "id-back":
      return (
        <UploadImageStep
          onClickHandler={onClickHandler}
          title={backTitle}
          nextStep={nextStepAfterDocumentUpload}
          attachment="BACK"
          identifier={identifier}
          apiKey={apiKey}
          isDone
          currentStep={component}
          isMobile={isMobile}
          text={backText}
          errorText={errorText}
          maxHeight={maxHeight}
          properties={properties}
          cameraFullScreenMobile={cameraFullScreenMobile}
        />
      );
    case "passport":
      return (
        <UploadImageStep
          onClickHandler={onClickHandler}
          title={passportTitle}
          nextStep={nextStepAfterDocumentUpload}
          attachment="FRONT"
          identifier={identifier}
          apiKey={apiKey}
          isDone
          currentStep={component}
          isMobile={isMobile}
          text={passportText}
          errorText={errorText}
          maxHeight={maxHeight}
          properties={properties}
          cameraFullScreenMobile={cameraFullScreenMobile}
        />
      );
    case "second":
      return (
        <SecondStep
          onClickHandler={onClickHandler}
          isMobile={isMobile}
          custom={custom?.selfieStep}
        />
      );
    case "selfie":
      return (
        <UploadSelfieStep
          onClickHandler={onClickHandler}
          nextStep={
            videoOption && !bimboCredentialOption
              ? "fourth"
              : bimboCredentialOption
                ? "third"
                : "end"
          }
          attachment="FACE"
          identifier={identifier}
          apiKey={apiKey}
          currentStep={component}
          isMobile={isMobile}
          maxHeight={maxHeight}
          cameraFullScreenMobile={cameraFullScreenMobile}
        />
      );
    case "check":
      return (
        <CheckImageStep
          onClickHandler={onClickHandler}
          backStep={checkImage?.currentStep}
          nextStep={checkImage?.nextStep}
          attachment={checkImage?.attachment}
          identifier={checkImage?.identifier}
          apiKey={checkImage?.apiKey}
          isDone={checkImage?.isDone}
          image={checkImage?.image}
          face={checkImage?.face}
          onlyCapture={onlyCapture}
          selfieOption={selfieOption}
          stepsDone={stepsDone}
          text={checkText}
          title={checkTitle}
          customDocument={custom?.checkId || custom?.checkPassport}
          deviceEncryptedPayload={deviceEncryptedPayload}
        />
      );
    case "waiting":
      return <WaitingStep />;
    case "send-mobile":
      return (
        <SendEmailStep
          onClickHandler={onClickHandler}
          identifier={identifier}
          apiKey={apiKey}
          isMobile={isMobile}
          custom={custom?.sendEmail}
        />
      );
    case "end":
      return (
        <FinalStep
          popup={popup}
          urlToRedirect={urlToRedirect}
          titleEnding={titleEnding}
          isMobile={isMobile}
          finalText={finalText}
          hideFinalButton={hideFinalButton}
          custom={custom?.end}
        />
      );
    case "not-found":
      return <NotFound isMobile={isMobile} />;
    case "only-mobile":
      return <OnlyMobile />;
    case "error":
      return (
        <Error
          isMobile={isMobile}
          onClickHandler={onClickHandler}
          bimboCredentialOption={Boolean(bimboCredentialOption)}
        />
      );
    case "information":
      return <Information isMobile={isMobile} titleEnding={titleEnding} />;
    case "third":
      return (
        <UploadCredential isMobile={isMobile} onClickHandler={onClickHandler} />
      );
    case "upload-bimbo-credential":
      return (
        <CaptureCredential
          isMobile={isMobile}
          onClickHandler={onClickHandler}
          apiKey={apiKey}
          currentStep={component}
          nextStep="check-credential"
          identifier={identifier}
        />
      );
    case "check-credential":
      return (
        <CheckCredential
          isMobile={isMobile}
          onClickHandler={onClickHandler}
          backStep={checkCredentials?.currentStep as string}
          identifier={checkCredentials?.identifier as string}
          apiKey={checkCredentials?.apiKey as string}
          images={checkCredentials?.credentials as string[]}
          nextStep={videoOption ? "fourth" : "end"}
        />
      );
    case "fourth":
      return (
        <VideoRecomendations
          onClickHandler={onClickHandler}
          isMobile={isMobile}
          status={status}
          globalResult={globalResult}
        />
      );
    case "video-information":
      return (
        <VideoInformation
          onClickHandler={onClickHandler}
          isMobile={isMobile}
          phrase={videoPhrase}
        />
      );
    case "video-capture":
      return (
        <VideoCapture
          onClickHandler={onClickHandler}
          isMobile={isMobile}
          phrase={videoPhrase}
          time={time}
          cameraFullScreenMobile={cameraFullScreenMobile}
          maxHeight={maxHeight}
          identifier={identifier}
          apiKey={apiKey}
        />
      );
    default:
      return <NotFound isMobile={isMobile} />;
  }
};

export default getComponent;
