import { useMemo } from "react";
import { BrowserRouter, Route, useLocation, Routes } from "react-router-dom";
import "./App.css";
import { appState } from "./utils/constants";

// Views
import Home from "./views/Home";

const useQuery = () => new URLSearchParams(useLocation().search);

const AppVdid = () => {
  const query = useQuery();
  const state = query.get("state");
  const {
    minHeight = "100dvh",
    identifier,
    apiKey,
    popup,
    mobile,
    onlyCapture = false,
    typeId = "process",
    maxHeight,
  } = useMemo(() => {
    try {
      if (!state) return appState;

      return JSON.parse(atob(state));
    } catch (error) {
      return appState;
    }
  }, [state]) as {
    minHeight: string;
    identifier: string;
    apiKey: string;
    popup: boolean;
    mobile: boolean;
    onlyCapture: boolean;
    typeId: string;
    maxHeight: string;
  };

  return (
    <div className="App" style={{ minHeight }}>
      <Home
        apiKey={apiKey}
        identifier={identifier}
        maxHeight={maxHeight}
        mobile={mobile}
        onlyCapture={onlyCapture}
        popup={popup}
        typeId={typeId}
      />
    </div>
  );
};

const App = () => (
  <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppVdid />} />
      </Routes>
    </BrowserRouter>
  </div>
);

export default App;
