import { useTranslation } from "react-i18next";
import notFoundIcon from "../../../assets/icons/warning.svg";
import { ErrorComponent } from "../../../utils/interfaces";

const Error = ({
  isMobile,
  onClickHandler,
  bimboCredentialOption,
}: ErrorComponent) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        isMobile ? "content_mobile_title_process" : "content_title_process"
      }
    >
      <h4>{t("errorSomethingWentWrong")}</h4>
      <img
        src={notFoundIcon}
        alt="completed"
        width={isMobile ? "140px" : "90px"}
        height={isMobile ? "140px" : "90px"}
        style={{ margin: "0 0 1.5rem" }}
      />
      <button
        type="button"
        className="primary-btn-actions"
        onClick={() =>
          onClickHandler({
            type: bimboCredentialOption ? "check-credential" : "check",
          })
        }
      >
        {t("tryAgain")}
      </button>
    </div>
  );
};

export default Error;
