import "./FinalStep.css";

// Assets
import finishedIcon from "../../assets/icons/finished.svg";
import { SDKcustom } from "../../utils/interfaces/config.interface";
import { useTranslation } from "react-i18next";

const FinalStep = ({
  popup,
  urlToRedirect,
  titleEnding,
  isMobile,
  finalText,
  custom,
  hideFinalButton,
}: {
  popup: boolean;
  titleEnding: string;
  isMobile: boolean;
  finalText: string;
  custom?: SDKcustom;
  hideFinalButton: boolean;
  urlToRedirect?: string;
}) => {
  const { t } = useTranslation();
  const title = custom?.content?.title ?? t("processEnd");
  const text = custom?.content?.text ?? titleEnding;
  const contentSecondaryBtn = isMobile
    ? custom?.buttons?.secondary?.mobileText
    : custom?.buttons?.secondary?.text;

  const onClickHandler = () => {
    if (urlToRedirect) {
      window.location.replace(urlToRedirect);

      return;
    }

    window.parent.postMessage({ completed: true }, { targetOrigin: "*" });
  };

  return (
    <>
      <div
        className={
          isMobile ? "content_mobile_title_process" : "content_title_process"
        }
      >
        <h4>{title}</h4>
        <p style={{ padding: "0 2rem" }}>{text ?? t("dataSendSuccess")}</p>
      </div>
      <div className={isMobile ? "FinalStep-Mobile-image" : "FinalStep-image"}>
        <img
          src={finishedIcon}
          alt="completed"
          className="FinalStep-img animate__animated animate__bounce"
        />
      </div>
      {/* Actions */}
      {hideFinalButton ? null : (
        <div
          className="actions_step"
          style={{
            textAlign: "center",
            position: "sticky",
            bottom: "0",
            marginBottom: isMobile ? "0" : "1.6rem",
          }}
        >
          {finalText && (
            <p style={{ padding: "0 2rem", color: "var(--font-main-color)" }}>
              {finalText}
            </p>
          )}
          {(urlToRedirect || !finalText) && (
            <button
              type="button"
              style={{
                ...custom?.buttons?.styles,
                margin: finalText ? "0.3rem 0 0" : "0",
              }}
              className="primary-btn-actions"
              onClick={onClickHandler}
            >
              {contentSecondaryBtn ?? t("end")}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default FinalStep;
