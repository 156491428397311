import { motion } from "framer-motion";
import { useEffect, useState, useRef, useCallback } from "react";
import { VideoCaptureComponent } from "../../utils/interfaces/components.interface";
import { createObjectURL } from "blob-util";
import { Video } from "./Video";
import { useTranslation } from "react-i18next";
import { Next, Retry } from "./Actions";
import { verifyVideo } from "../../utils/call/callVideoLiveness";
const videoConstraints = { facingMode: "user" };

const VideoCapture = ({
  onClickHandler,
  isMobile,
  phrase,
  time,
  cameraFullScreenMobile,
  maxHeight,
  identifier,
  apiKey,
}: VideoCaptureComponent) => {
  const { t } = useTranslation();
  // Video
  const webcamRef = useRef<any>(null);
  const mediaRecordRef = useRef<any>(null);
  const [video, setVideo] = useState<string>("");
  const [source, setSource] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [counter, setCounter] = useState<number>(time || 15);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [initialCounter, setInitialCounter] = useState<number>(4);
  const [initialState, setInitialState] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageError, setMessageError] = useState({
    message: "",
    severity: "info",
    visibility: false,
  });

  const startCapture = () => setInitialState(true);

  const handleDataAvailable = useCallback(
    ({ data }: any) => {
      if (data.size > 0) setRecordedChunks((prev) => prev.concat(data));
    },
    [setRecordedChunks],
  );

  useEffect(() => {
    const initialTimer =
      initialCounter > 0 &&
      initialState &&
      setInterval(() => setInitialCounter(initialCounter - 1), 1000);
    if (initialCounter <= 1) {
      if (webcamRef?.current?.stream) {
        mediaRecordRef.current = new MediaRecorder(webcamRef.current.stream);
        mediaRecordRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable,
        );
        mediaRecordRef.current.start();
        setIsRecording(true);
      }
    }
    return () => clearInterval(initialTimer as any);
  }, [initialCounter, initialState, handleDataAvailable]);

  useEffect(() => {
    if (
      isRecording &&
      mediaRecordRef.current &&
      mediaRecordRef.current.state === "recording"
    ) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      if (counter <= 0) {
        webcamRef.current.stream
          .getTracks()
          .forEach((track: any) => track.stop());
        mediaRecordRef.current.stop();
        setVideo("");
        setIsRecording(false);
        clearInterval(timer as any);
      }
      return () => clearInterval(timer as any);
    }
  }, [counter, isRecording]);

  useEffect(() => {
    const getVideo = async () => {
      const source = createObjectURL(recordedChunks[0]);
      setSource(source);
      setVideo(recordedChunks[0]);
    };
    if (recordedChunks && recordedChunks.length > 0) getVideo();
  }, [recordedChunks]);

  // Reset
  const resetVideo = () => {
    setVideo("");
    setError(null);
    setCounter(time);
    setRecordedChunks([]);
    setIsRecording(false);
    setInitialCounter(4);
    setInitialState(false);
    setMessageError({
      message: "",
      severity: "err",
      visibility: false,
    });
  };

  //Verify
  const videoLiveness = async () => {
    setIsLoading(true);
    try {
      await verifyVideo({
        data: {
          phrase: phrase,
          uuid: identifier,
          video: video,
        },
        onClickHandler,
        apiKey,
        t,
        setMessageError,
      });
    } catch (error) {
      setMessageError({
        message: t("errorVideo"),
        severity: "err",
        visibility: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={
        isMobile && cameraFullScreenMobile ? "video-capture-fullscreen" : ""
      }
    >
      <div
        className={
          isMobile
            ? cameraFullScreenMobile
              ? "video-capture-title-fullscreen"
              : "video-capture-title-mobile"
            : "video-capture-title"
        }
      >
        {messageError.visibility ? (
          <span className={`video-capture-error ${messageError.severity}`}>
            {messageError.message}
          </span>
        ) : (
          <p>{phrase || ""}</p>
        )}
      </div>
      <div
        className={
          isMobile
            ? cameraFullScreenMobile
              ? "video-capture-content-fullscreen"
              : "video-capture-content-mobile"
            : "video-capture-content"
        }
      >
        <div
          className="content_webcam_client"
          style={{ display: error ? "none" : "block", maxHeight }}
        >
          {video === "" && error === null ? (
            <Video
              counter={counter}
              setError={setError}
              webcamRef={webcamRef}
              initialCounter={initialCounter}
              videoConstraints={videoConstraints}
              handleStartCaptureClick={startCapture}
            />
          ) : video !== "" && source !== "" && error === null ? (
            <video controls src={source}></video>
          ) : (
            <div
              style={{
                padding: "1.4rem 1.2rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <h4>{t("errorSomethingWentWrong")}</h4>
              <p style={{ margin: "1rem 0" }}>{error}</p>
              <motion.button
                type="button"
                exit={{ scale: 1 }}
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.1 }}
                className="primary-btn-actions"
                transition={{
                  type: "spring",
                  bounce: 0,
                  stiffness: 400,
                  damping: 15,
                }}
                onClick={() => window.location.reload()}
              >
                {t("reload")}
              </motion.button>
            </div>
          )}
        </div>
        {video !== "" && error === null && (
          <div className="two_buttons">
            <Retry title={t("tryAgain")} handleTryAgain={resetVideo} />
            <Next
              title={isLoading ? t("") : t("continue")}
              handleNext={videoLiveness}
              disabled={isLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCapture;
