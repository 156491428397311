import { GET_OPTIONS, GET_CLIENT_OPTIONS } from "../constants";

const getOptions = async (
  identifier: string,
  apiKey: string,
  onlyCapture: boolean,
) => {
  try {
    const url = onlyCapture
      ? GET_CLIENT_OPTIONS
      : `${GET_OPTIONS}/${identifier}`;
    const response = await fetch(url, {
      method: "GET",
      headers: { "x-api-key": apiKey },
    });

    if (response.ok) return response.json();
  } catch (error) {
    console.log(error);
    return { step: "not-found", url: undefined };
  }
};

export default getOptions;
