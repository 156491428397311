import idCard from "../../assets/icons/id_option.svg";
import selfie from "../../assets/icons/selfie_option.svg";
import liveness from "../../assets/icons/liveness_option.svg";
import processData from "../../assets/icons/process_data.svg";
import { Return } from "../UI/Return/Return";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { ProcessStep } from "./ProcessStep";
import { ProcessComponent } from "../../utils/interfaces";
import { useTranslation } from "react-i18next";
import ViamericasRecommends from "./ViamericasRecommends";

const Process = ({
  onClickHandler,
  isMobile,
  custom,
  selfieOption,
  firstStepText,
  firstStepTitle,
  bimboCredentialOption,
  videoOption,
}: ProcessComponent) => {
  const showPattern = custom?.content?.bgPattern ?? true;
  const { t } = useTranslation();

  return (
    <>
      <Return
        handleAction={() => onClickHandler({ type: "welcome" })}
        isMobile={isMobile}
        isProcess={custom?.content?.badge ?? true}
      />

      <div style={{ width: "100%", height: "80%" }}>
        {custom && custom?.view && custom?.view === "viamericas-recommends" ? (
          <ViamericasRecommends
            idCard={idCard}
            isMobile={isMobile}
            showPattern={showPattern}
          />
        ) : (
          <Swiper
            pagination={{ clickable: true }}
            modules={[Pagination]}
            className="mySwiper"
            style={{ height: "100%" }}
          >
            <SwiperSlide key={"1_swiper_slide"}>
              <ProcessStep
                key="step1"
                custom={custom}
                isMobile={isMobile}
                sourceImage={idCard}
                stepNumber="1."
                titleStep={firstStepTitle}
                contentStep={firstStepText}
              />
            </SwiperSlide>
            {selfieOption ? (
              <SwiperSlide key={"2_swiper_slide"}>
                <ProcessStep
                  key="step2"
                  custom={custom}
                  isMobile={isMobile}
                  sourceImage={selfie}
                  stepNumber="2."
                  titleStep={t("takeSelfie")}
                  contentStep={t("mustLocateFace")}
                />
              </SwiperSlide>
            ) : null}

            {selfieOption ? (
              <SwiperSlide key={"3_swiper_slide"}>
                <ProcessStep
                  key="step3"
                  custom={custom}
                  isMobile={isMobile}
                  sourceImage={liveness}
                  stepNumber="3."
                  titleStep={t("livenessTest")}
                  contentStep={t("livenessMessage")}
                />
              </SwiperSlide>
            ) : null}

            {bimboCredentialOption && (
              <>
                <SwiperSlide key={"4_swiper_slide"}>
                  <ProcessStep
                    key="step5"
                    custom={custom}
                    isMobile={isMobile}
                    sourceImage={idCard}
                    stepNumber={selfieOption ? `4.` : `2.`}
                    titleStep={t("scanCredentialEmployee")}
                    contentStep={t("mustLocateCredentialEmployee")}
                  />
                </SwiperSlide>
                <SwiperSlide key={"5_swiper_slide"}>
                  <ProcessStep
                    key="step6"
                    custom={custom}
                    isMobile={isMobile}
                    sourceImage={processData}
                    stepNumber={selfieOption ? `5.` : `3.`}
                    titleStep={t("ocrTitle")}
                    contentStep={t("ocrMessage")}
                  />
                </SwiperSlide>
              </>
            )}

            {videoOption ? (
              <SwiperSlide key={"6_swiper_slide"}>
                <ProcessStep
                  key="step7"
                  custom={custom}
                  isMobile={isMobile}
                  sourceImage={liveness}
                  stepNumber={
                    bimboCredentialOption && selfieOption
                      ? `6.`
                      : bimboCredentialOption || selfieOption
                        ? `4.`
                        : `2.`
                  }
                  titleStep={t("recordVideo")}
                  contentStep={t("sureFollowInstructions")}
                />
              </SwiperSlide>
            ) : null}

            <SwiperSlide key={"7_swiper_slide"}>
              <ProcessStep
                key="step4"
                custom={custom}
                isMobile={isMobile}
                sourceImage={processData}
                stepNumber={
                  videoOption && bimboCredentialOption && selfieOption
                    ? `7.`
                    : bimboCredentialOption && selfieOption && !videoOption
                      ? `6.`
                      : videoOption && (bimboCredentialOption || selfieOption)
                        ? `5.`
                        : (bimboCredentialOption || selfieOption) &&
                            !videoOption
                          ? `4.`
                          : videoOption &&
                              !bimboCredentialOption &&
                              !selfieOption
                            ? `3.`
                            : `2.`
                }
                titleStep={t("processData")}
                contentStep={t("processDataSend")}
              />
            </SwiperSlide>
          </Swiper>
        )}
      </div>
      <div
        className={
          isMobile
            ? "actions_step_two_columns_mobile"
            : "actions_step_two_columns"
        }
        style={{ textAlign: "center" }}
      >
        {isMobile ? null : (
          <button
            type="button"
            style={custom?.buttons?.styles}
            className="secondary-btn-actions"
            onClick={() => onClickHandler({ type: "welcome" })}
          >
            {t("goBack")}
          </button>
        )}
        <button
          type="button"
          style={{
            ...custom?.buttons?.styles,
            ...(isMobile && custom?.buttons?.mobileStyle),
          }}
          className="primary-btn-actions"
          onClick={() => onClickHandler({ type: "first" })}
        >
          {t("continue")}
        </button>
      </div>
    </>
  );
};

export default Process;
