import { useEffect } from "react";
import "@innovatrics/dot-magnifeye-liveness";
import "@innovatrics/dot-auto-capture-ui/magnifeye-liveness";
import {
  MagnifEyeLivenessProps,
  HTMLMagnifEyeLivenessElement,
  OnCompleteData,
} from "@innovatrics/dot-magnifeye-liveness";
import type {
  MagnifEyeLivenessUiProps,
  HTMLMagnifEyeLivenessUiElement,
} from "@innovatrics/dot-auto-capture-ui/magnifeye-liveness";

const MagnifEyeLiveness = (props: MagnifEyeLivenessProps) => {
  useEffect(() => {
    const magnifEyeHTMLElement = document.getElementById(
      "x-dot-magnifeye-liveness",
    ) as HTMLMagnifEyeLivenessElement | null;

    if (magnifEyeHTMLElement) {
      magnifEyeHTMLElement.props = props;
    }
  });

  return <x-dot-magnifeye-liveness id="x-dot-magnifeye-liveness" />;
};

const MagnifEyeLivenessUi = (props: MagnifEyeLivenessUiProps) => {
  useEffect(() => {
    const uiElement = document.getElementById(
      "x-dot-magnifeye-liveness-ui",
    ) as HTMLMagnifEyeLivenessUiElement | null;

    if (uiElement) {
      uiElement.props = props;
    }
  });

  return <x-dot-magnifeye-liveness-ui id="x-dot-magnifeye-liveness-ui" />;
};

export { MagnifEyeLiveness, MagnifEyeLivenessUi, type OnCompleteData };
