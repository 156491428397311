import { useTranslation } from "react-i18next";
import notFoundIcon from "../../../assets/icons/warning.svg";

export const ScrollMultipleCaptures = ({
  images,
  isMobile,
}: {
  images: string[];
  isMobile: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        position: "relative",
        overflow: "auto",
        padding: "0.6rem 0 0 0",
        whiteSpace: "nowrap",
        marginBottom: "1rem",
        background: "transparent",
      }}
    >
      <div style={{ display: "inline-block", columnGap: "10px" }}>
        {images && images.length > 0 ? (
          images.map((img, i: number) => (
            <img
              key={i}
              src={`data:image/jpeg;base64,${img}`}
              width={isMobile ? "78%" : "89%"}
              alt={`capture ${i}`}
              style={{ margin: "0 1rem 0 0", borderRadius: "10px" }}
            />
          ))
        ) : (
          <div
            style={{
              padding: "1.5rem 1.4rem",
              textAlign: "center",
              backgroundColor: "#efefef",
              borderRadius: "10px",
              marginBottom: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={notFoundIcon}
              alt="not_found"
              width={isMobile ? "140px" : "90px"}
            />
            <p>{t("notFoundImage")}</p>
          </div>
        )}
      </div>
    </div>
  );
};
