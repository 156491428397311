const ViamericasRecommends = ({
  isMobile,
  showPattern,
  idCard,
}: {
  isMobile: boolean;
  showPattern: boolean;
  idCard: string;
}) => (
  <div style={{ height: "100%", padding: "0 1rem" }}>
    <div
      className={
        isMobile
          ? !showPattern
            ? "content_without_pattern"
            : "content_main_images_step_mobile"
          : !showPattern
            ? "content_without_pattern"
            : "content_main_images_step"
      }
    >
      <img src={idCard} alt="card" width={100} height={80} />
    </div>

    <div style={{ padding: "0 1rem" }}>
      <h4
        style={{
          textAlign: "center",
          paddingBottom: "0.9rem",
          color: "var(--font-title)",
        }}
      >
        ID Image Recomendations
      </h4>
      <div
        style={{
          textAlign: "left",
          fontSize: "clamp(0.8rem, 0.83rem, 0.86rem)",
          marginBottom: "8px",
        }}
      >
        <p style={{ color: "var(--blue-accent)" }}>
          <strong>USA Identification</strong>
        </p>
        <p>
          Please upload a US issued government ID, if you have one, to avoid
          potential delays processing your transaction.
        </p>
      </div>

      <div
        style={{
          textAlign: "left",
          fontSize: "clamp(0.8rem, 0.83rem, 0.86rem)",
          marginBottom: "8px",
        }}
      >
        <p style={{ color: "var(--blue-accent)" }}>
          <strong>Good Lighting</strong>
        </p>
        <p>
          Make sure you are in a well-lit room and that no glare is present in
          pictures.
        </p>
      </div>

      <div
        style={{
          textAlign: "left",
          fontSize: "clamp(0.8rem, 0.83rem, 0.86rem)",
        }}
      >
        <p style={{ color: "var(--blue-accent)" }}>
          <strong>Complete, Clear and Legible</strong>
        </p>
        <p>
          Pictures that do not meet this criteria will be on hold until issue is
          resolved.
        </p>
      </div>
    </div>
  </div>
);

export default ViamericasRecommends;
