// Assets
import { useTranslation } from "react-i18next";
import selfie from "../../assets/icons/selfie.svg";
import { SecondStepComponent } from "../../utils/interfaces";

const SecondStep = ({
  onClickHandler,
  isMobile,
  custom,
}: SecondStepComponent) => {
  const { t } = useTranslation();

  const showPattern = custom?.content?.bgPattern ?? true;
  const text = custom?.content?.text ?? t("takeYouSelfie");
  type DeviceMotionEventWithPermissions = {
    requestPermission: () => Promise<PermissionState>;
  } & DeviceMotionEvent;

  const uploadImage = async () => {
    // Only for iOS 13+
    const { requestPermission } =
      DeviceMotionEvent as unknown as DeviceMotionEventWithPermissions;

    if (typeof requestPermission === "function") {
      const response = await requestPermission();

      if (response === "granted") {
        window.addEventListener("devicemotion", () => {});
      } else {
        console.error(t("permissionDenied"));
      }
    }

    // Next step
    onClickHandler({ type: "selfie" });
  };

  return (
    <>
      <div
        className={
          isMobile
            ? !showPattern
              ? "content_without_pattern"
              : "content_main_images_step_mobile"
            : !showPattern
              ? "content_without_pattern"
              : "content_main_images_step"
        }
      >
        <img src={selfie} alt="selfie" width="auto" height="120px" />
      </div>
      <div
        className={
          isMobile
            ? "content_main_information_step_mobile"
            : "content_main_information_step"
        }
      >
        {/* Information */}
        <div
          className="information_step"
          style={{ marginBottom: isMobile ? "40px" : "0px" }}
        >
          <h3>{t("stepTwo")}</h3>
          <p
            className={
              isMobile
                ? "description_information_step_mobile"
                : "description_information_step"
            }
            style={{ fontSize: "clamp(0.8rem, 0.83rem, 0.86rem)" }}
          >
            {text}
          </p>

          {isMobile &&
            custom &&
            custom?.view &&
            custom?.view === "viamericas-selfie-recommends" && (
              <div style={{ paddingTop: "10px" }}>
                <p
                  style={{
                    fontSize: "clamp(0.8rem, 0.83rem, 0.86rem)",
                    fontWeight: "600",
                    padding: "0.5rem 0",
                  }}
                >
                  Please follow these recommendations:
                </p>

                <div
                  style={{
                    textAlign: "left",
                    fontSize: "clamp(0.8rem, 0.83rem, 0.86rem)",
                    marginBottom: "8px",
                  }}
                >
                  <p style={{ color: "var(--blue-accent)" }}>
                    <strong>Look straight</strong>
                  </p>
                  <p>
                    Hold phone at eye level and look straight into camera with
                    ears visible when taking your selfie.
                  </p>
                </div>

                <div
                  style={{
                    textAlign: "left",
                    fontSize: "clamp(0.8rem, 0.83rem, 0.86rem)",
                    marginBottom: "8px",
                  }}
                >
                  <p style={{ color: "var(--blue-accent)" }}>
                    <strong>Good Lighting</strong>
                  </p>
                  <p>
                    Make sure you are in a well-lit room and that no glare is
                    present in pictures.
                  </p>
                </div>
              </div>
            )}
        </div>
        {/* Actions */}
        <div className="actions_step">
          <button
            type="button"
            style={{
              ...custom?.buttons?.styles,
              ...(isMobile && custom?.buttons?.mobileStyle),
            }}
            className="primary-btn-actions"
            onClick={uploadImage}
          >
            {t("continue")}
          </button>
        </div>
      </div>
    </>
  );
};

export default SecondStep;
