const API_URL = "https://veridocid.azure-api.net/api/id/v3";

const UPLOAD_FILE = `${API_URL}/uploadVerificationFile`;
const GET_STEP = `${API_URL}/getVerificationStep`;
const GEOLOCATION_SDK = `${API_URL}/geolocationSDK`;
const GET_OPTIONS = `${API_URL}/getOptions`;
const GET_CLIENT_OPTIONS = `${API_URL}/getClientOptions`;
const VIDEO_LIVENESS = `${API_URL}/sdk/verify-video`;

// Para los cambios en local
// const UPLOAD_FILE = `${API_URL}/sdk/upload`;
// const GET_STEP = `${API_URL}/step`;
// const GEOLOCATION_SDK = `${API_URL}/sdk/geolocation`;
// const GET_OPTIONS = `${API_URL}/options`;
// const GET_CLIENT_OPTIONS = `http://localhost:3000/api/v3/client`;
// const VIDEO_LIVENESS = http://localhost:3001/api/v3/id-verification/sdk/video

export {
  GEOLOCATION_SDK,
  GET_CLIENT_OPTIONS,
  GET_OPTIONS,
  GET_STEP,
  UPLOAD_FILE,
  VIDEO_LIVENESS,
};
