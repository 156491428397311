import { useState } from "react";
import Spinner from "../UI/Spinner/Spinner";
import uploadImage from "../../utils/uploadImage";
import { ScrollMultipleCaptures } from "../UI/Capture";
import { CheckCredentialsStepComponent } from "../../utils/interfaces";
import { useTranslation } from "react-i18next";

const CheckCredential = ({
  apiKey,
  backStep,
  identifier,
  images,
  isMobile,
  onClickHandler,
  nextStep,
}: CheckCredentialsStepComponent) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkOcr = async () => {
    try {
      setIsLoading(true);

      if (images?.length) {
        let file1 = images[0]
          .replace("data:image/png;base64,", "")
          .replace("data:image/jpeg;base64,", "");
        let file2 = images[1]
          .replace("data:image/png;base64,", "")
          .replace("data:image/jpeg;base64,", "");

        //First upload of front credential
        const { step, error } = await uploadImage({
          apiKey,
          attachment: "OCR",
          done: false,
          image: file1,
          onlyCapture: false,
          uuid: identifier,
          ocr: { process: true, scanBarcodes: false },
        });

        if (error) {
          setIsLoading(false);
          onClickHandler({ type: "error" });
          return;
        } else {
          //Second upload of back credential
          let { error, status, globalResult } = await uploadImage({
            image: file2,
            attachment: "OCR",
            done: true,
            uuid: identifier,
            apiKey,
            onlyCapture: false,
            ocr: { process: true, scanBarcodes: true },
          });

          if (error) {
            setIsLoading(false);
            onClickHandler({ type: "error" });
            return;
          }

          if (step === "PROCESS_VERIFICATION") {
            onClickHandler({
              type: "end",
              opt: {
                titleEnd: t("dataSendSuccess"),
              },
            });
            setIsLoading(false);
          } else {
            onClickHandler({
              type: nextStep,
              opt: { status: status, globalResult: globalResult },
            });
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div
        className={
          isMobile ? "content_mobile_title_process" : "content_title_process"
        }
      >
        <h4>{t("capturedImages")}</h4>
        <p>{t("checkImages")}</p>
      </div>
      <div
        className={
          isMobile ? "content_mobile_title_process" : "content_title_process"
        }
      >
        <ScrollMultipleCaptures images={images} isMobile={isMobile} />
        {/* Actions */}
        <div
          className={
            isMobile
              ? "actions_step_two_columns_mobile"
              : "actions_step_two_columns"
          }
          style={{ textAlign: "center" }}
        >
          <button
            type="button"
            className="secondary-btn-actions"
            onClick={() => onClickHandler({ type: backStep })}
          >
            {t("takeAgain")}
          </button>
          <button
            type="button"
            className="primary-btn-actions"
            onClick={checkOcr}
          >
            {t("continue")}
          </button>
        </div>
      </div>
    </>
  );
};

export default CheckCredential;
