import { useTranslation } from "react-i18next";
import "./Spinner.css";

const Spinner = () => {
  const { t } = useTranslation();

  return (
    <div className="content_loader">
      {/* <div className="Loader"></div> */}
      {/* ellipse loading */}
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span className="description_loader">{t("enjoy")}</span>
    </div>
  );
};

export default Spinner;
