export interface Properties {
  brightnessHigh?: number;
  brightnessLow?: number;
  hotspotsScore?: number;
  confidence?: number;
  sharpness?: number;
  sizeSmall?: number;
  outOfBounds?: number;
}

export interface SDKcustom {
  content?: {
    bgPattern?: boolean;
    logo?: boolean;
    title?: string;
    text?: string;
    seletcTypeIdColumn?: boolean;
    badge?: boolean;
    customIcon?: string;
  };
  buttons?: {
    styles?: React.CSSProperties;
    mobileStyle?: React.CSSProperties;
    primary?: { text?: string; mobileText?: string };
    secondary?: { text?: string; mobileText?: string };
  };
  extra?: { title?: string; text?: string };
  view?: string;
}
interface CustomProperties {
  welcome?: SDKcustom;
  sendEmail?: SDKcustom;
  documentStep?: SDKcustom;
  selfieStep?: SDKcustom;
  front?: SDKcustom;
  back?: SDKcustom;
  passport?: SDKcustom;
  checkId?: SDKcustom;
  checkPassport?: SDKcustom;
  processId?: SDKcustom;
  processPassport?: SDKcustom;
  end?: SDKcustom;
}

interface ClientSdkConfiguration {
  hasCustomProperties: boolean;
  hideFinalButton: boolean;
  buttonColor?: string;
  buttonSecondaryColor?: string;
  cameraFullScreenMobile?: boolean;
  componentColor?: string;
  componentResponsiveColor?: string;
  errorCameraText?: string;
  finalText?: string;
  icon?: string;
  ine?: boolean;
  logo?: string;
  onlyCapture?: boolean;
  renapo?: boolean;
  selfie?: boolean;
  textColor?: string;
  textColorTitle?: string;
  wallpaperColor?: string;
  properties?: Properties;
  custom?: CustomProperties;
  badgeColor?: string;
  cardOptionBorder?: string;
  cardOptionBackground?: string;
  cardOptionBackgroundActive?: string;
  textColorCardOption?: string;
}

export enum VerificationStatus {
  Checked = "Checked",
  WaitingChecking = "WaitingChecking",
  ManualIdentification = "ManualIdentification",
  ManualChecking = "ManualChecking",
  Failed = "Failed",
  WaitingData = "WaitingData",
  Deleted = "Deleted",
}

export enum GlobalResult {
  ok = "Ok",
  failed = "Failed",
  differentPerson = "DifferentPerson",
  documentUnacceptable = "DocumentUnacceptable",
  documentUnprocessed = "DocumentUnprocessed",
  documentExpired = "DocumentExpired",
  controlListHits = "ControlListHits",
  failedBiometrics = "FailedBiometrics",
  lowImageQuality = "LowImageQuality",
  warning = "Warning",
  high = "High",
  medium = "Medium",
  low = "Low",
}
export default ClientSdkConfiguration;
