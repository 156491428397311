import { useEffect } from "react";
import "@innovatrics/dot-document-auto-capture";
import "@innovatrics/dot-auto-capture-ui/document";
import type {
  HTMLDocumentCaptureElement,
  DocumentCameraProps,
  DocumentCallback,
} from "@innovatrics/dot-document-auto-capture";
import type {
  DocumentUiProps,
  HTMLDocumentUiElement,
} from "@innovatrics/dot-auto-capture-ui/document";

const DocumentCamera = (props: DocumentCameraProps) => {
  useEffect(() => {
    const documentAutoCaptureHTMLElement = document.getElementById(
      "x-dot-document-auto-capture",
    ) as HTMLDocumentCaptureElement | null;

    if (documentAutoCaptureHTMLElement) {
      documentAutoCaptureHTMLElement.cameraOptions = props;
    }
  });

  return <x-dot-document-auto-capture id="x-dot-document-auto-capture" />;
};

const DocumentUi = (props: DocumentUiProps) => {
  useEffect(() => {
    const uiElement = document.getElementById(
      "x-dot-document-auto-capture-ui",
    ) as HTMLDocumentUiElement | null;

    if (uiElement) {
      uiElement.props = props;
    }
  });

  return <x-dot-document-auto-capture-ui id="x-dot-document-auto-capture-ui" />;
};

export { DocumentCamera, DocumentUi, type DocumentCallback };
