import { motion } from "framer-motion";

const Next = ({
  title,
  handleNext,
  disabled,
}: {
  title: string;
  handleNext: () => void;
  disabled?: boolean;
}) => (
  <motion.button
    type="button"
    exit={{ scale: 1 }}
    whileTap={{ scale: 0.95 }}
    whileHover={{ scale: 1.1 }}
    className="primary-btn-actions"
    transition={{
      type: "spring",
      bounce: 0,
      stiffness: 400,
      damping: 15,
    }}
    onClick={handleNext}
    disabled={disabled}
  >
    {disabled ? <div className="loading-spinner"></div> : title}
  </motion.button>
);

const Retry = ({
  title,
  handleTryAgain,
  disabled,
}: {
  title: string;
  handleTryAgain: () => void;
  disabled?: boolean;
}) => (
  <motion.button
    type="button"
    className="secondary-btn-actions"
    exit={{ scale: 1 }}
    whileTap={{ scale: 0.95 }}
    whileHover={{ scale: 1.1 }}
    transition={{ type: "spring", bounce: 0, stiffness: 400, damping: 15 }}
    onClick={handleTryAgain}
    {...(disabled && { disabled: disabled })}
  >
    {title}
  </motion.button>
);

export { Next, Retry };
