import { VerifyVideo } from "../interfaces/components.interface";
import { VIDEO_LIVENESS } from "../constants/endpoints";

const verifyVideo = async ({
  data,
  onClickHandler,
  apiKey,
  t,
  setMessageError,
}: VerifyVideo) => {
  try {
    const formData = new FormData();
    formData.append("frase", data.phrase.toString());
    formData.append("uuid", data.uuid.toString());
    formData.append("video", data.video);

    const params = {
      method: "POST",
      headers: { "x-api-key": apiKey },
      body: formData,
    };

    const response = await fetch(VIDEO_LIVENESS, params);
    if (response.ok) {
      const result = await response.json();
      if (result?.status && result?.status === "failed") {
        setMessageError({
          message: result?.videoResult,
          severity: "err",
          visibility: true,
        });
        return;
      }

      if (result?.status && result?.status === "ok") {
        onClickHandler({
          type: "end",
          opt: {
            titleEnd: t("dataSendSuccess"),
          },
        });
        return;
      }
    } else {
      const error = await response.json();
      if (error?.error) {
        if (error?.error) {
          setMessageError({
            message: error?.error,
            severity: "err",
            visibility: true,
          });
          return;
        } else {
          setMessageError({
            message: t("errorVideo"),
            severity: "err",
            visibility: true,
          });
          return;
        }
      } else {
        setMessageError({
          message: t("errorVideo"),
          severity: "err",
          visibility: true,
        });
        return;
      }
    }
  } catch (error) {
    console.error("Error en el proceso de video", error);
    setMessageError({
      message: t("errorVideo"),
      severity: "err",
      visibility: true,
    });
  }
};

export { verifyVideo };
