// Assets
import { useEffect, useState } from "react";
import selfie from "../../assets/icons/selfie.svg";
import { VideoInformationComponent } from "../../utils/interfaces/components.interface";
import { useTranslation } from "react-i18next";

const VideoInformation = ({
  onClickHandler,
  isMobile,
  phrase,
}: VideoInformationComponent) => {
  const { t } = useTranslation();
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    const regex = /[ ]+/g;
    const cleanPhrase = phrase.replace(regex, " ");
    const lengthPhrase = cleanPhrase.split(" ").length;

    if (lengthPhrase < 7) {
      setTime(6);
    } else if (lengthPhrase >= 7 && lengthPhrase <= 15) {
      setTime(9);
    } else if (lengthPhrase >= 16 && lengthPhrase <= 19) {
      setTime(12);
    } else if (lengthPhrase >= 20 && lengthPhrase <= 24) {
      setTime(15);
    } else if (lengthPhrase >= 70 && lengthPhrase <= 90) {
      setTime(40);
    } else {
      setTime(20);
    }
  }, [phrase]);

  const nextStep = async () => {
    onClickHandler({
      type: "video-capture",
      opt: {
        time: time,
      },
    });
  };

  return (
    <>
      <div
        className={
          isMobile
            ? "content_main_images_step_mobile"
            : "content_main_images_step"
        }
      >
        <img src={selfie} alt="selfie" width="auto" height="130px" />
      </div>
      <div
        className={
          isMobile
            ? "content_main_information_step_mobile"
            : "content_main_information_step"
        }
      >
        {/* Information */}
        <div
          className="information_step"
          style={{ marginBottom: isMobile ? "40px" : "0px" }}
        >
          <h3>{t("recordVideo")}</h3>
          <span
            className={
              isMobile
                ? "description_information_step_mobile"
                : "description_information_step"
            }
          >
            <p
              style={{
                fontSize: "clamp(0.78rem, 0.79rem, 0.8rem)",
                color: "var(--font-accent)",
              }}
            >
              {t("videoProcessIndication")} {time || 15}{" "}
              {t("videoProcessIndicationEnd")}: &quot;
              <i>{phrase || ""}</i>&quot;.
            </p>
          </span>
        </div>
        {/* Actions */}
        <div className="actions_step">
          <button
            type="button"
            className="primary-btn-actions"
            onClick={nextStep}
          >
            {t("continue")}
          </button>
        </div>
      </div>
    </>
  );
};

export default VideoInformation;
